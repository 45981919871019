import { FC, InputHTMLAttributes, ReactNode, Ref, forwardRef } from 'react';

import { LiteralUnion, RegisterOptions } from 'react-hook-form';

import { Label } from './Label';
import { Icon } from './Icon';
import { CustomCallback } from './Modal/interface';

export interface TextFieldProps extends InputHTMLAttributes<HTMLInputElement> {
  className?: string;
  wrapperClassName?: string;
  PrefixEl?: string | ReactNode;
  SuffixEl?: string | ReactNode;
  label?: string;
  inputBgColor?: string;
  ref?: Ref<HTMLInputElement>;
  isError?: boolean;
  errorMessage?: string;
  onCloseButton?: CustomCallback<any, any>;
  buttonText?: string;
  buttonClassName?: string;
  onButton?: CustomCallback<any, any>;
}

const TextField: FC<TextFieldProps> = forwardRef(
  (
    {
      className,
      wrapperClassName,
      PrefixEl,
      SuffixEl,
      label,
      inputBgColor = 'gray',
      isError,
      errorMessage,
      onCloseButton,
      buttonText,
      buttonClassName,
      onButton,
      ...props
    },
    ref,
  ) => {
    const setInputBgColor = () => {
      return inputBgColor === 'gray' ? 'bg-[#F3F3F3]' : 'bg-key-9';
    };

    return (
      <div className={` ${wrapperClassName ? wrapperClassName : 'w-full'}`}>
        {label && <Label>{label}</Label>}
        <div className="relative">
          <div className="relative flex items-center gap-x-2">
            <div className="relative w-full">
              {PrefixEl && (
                <div className="absolute left-2 top-0 flex h-full items-center font-bold">
                  {PrefixEl}
                </div>
              )}
              <input
                {...props}
                ref={ref}
                className={`w-full rounded-xl py-3 text-14 leading-[17px] text-gray-1-active placeholder:text-gray-3 placeholder-shown:truncate focus:ring-2 focus:ring-gray-800 ${className} ${
                  isError ? 'border border-point-warning' : 'border border-transparent'
                }   ${PrefixEl && 'pl-9'}  ${SuffixEl && 'pr-9'} ${setInputBgColor()} `}
              />
              {SuffixEl && (
                <div className="absolute right-2 top-0 flex h-full items-center font-bold">
                  {SuffixEl}
                </div>
              )}
            </div>
            {buttonText && (
              <button
                type="button"
                className={
                  buttonClassName ??
                  'w-[30%] min-w-[84px] shrink-0 px-5 py-2 text-12 button-primary'
                }
                onClick={onButton}
              >
                {buttonText}
              </button>
            )}
          </div>
        </div>
        {isError && errorMessage && (
          <p className="pt-1 text-left text-point-warning">{errorMessage}</p>
        )}
      </div>
    );
  },
);

export { TextField };
