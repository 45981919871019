import { useMemo } from 'react';

import { useRecoilValue } from 'recoil';

import { useLocation } from 'react-use';
import { useAuth } from '@hooks/useAuth';
import { fetcher, queryString, useRecoilQuery } from '@hooks';

import {
  jobsViewCountAtom,
  jobsPollingStatusAtomFamily,
  jobsPollingDataAtomFamily,
  jobsCurrentPageAtom,
} from '../state/JobContainer.atom';
import { jobsPollingSelectorFamily } from '../state/jobContainer.selector';

const populate = [
  { path: 'tasks' },
  { path: 'input.source' },
  { path: 'input.target' },
  { path: 'input.optical' },
  { path: 'input.card' },

  { path: 'output.swap' },
  { path: 'output.enhance' },
  { path: 'output.optical' },
  { path: 'output.card' },
];

export const MIN_DATE = '2023-11-02T02:00:00.000Z';
export const JOBS_URL = 'general/job';

const useGetJobsPolling = ({ userId }: { userId: string }) => {
  const { isAuthenticated } = useAuth();
  // const status = useSearchParam('status');
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const status = queryParams.get('status');

  const jobsViewCount = useRecoilValue(jobsViewCountAtom);
  const jobsCurrPage = useRecoilValue(jobsCurrentPageAtom);
  const skipCount = (jobsCurrPage - 1) * jobsViewCount;

  const { data: jobsPolling, status: jobsStatus } = useRecoilValue(
    jobsPollingSelectorFamily({ userId }),
  );

  const args = useMemo(() => {
    const filter: any = {
      user: userId,
      createdAt: { $gte: MIN_DATE },
    };
    if (status) {
      filter.status = status;
    }
    return {
      filter,
      options: { sort: { createdAt: -1 }, skip: skipCount, limit: jobsViewCount },
      populate,
    };
  }, [jobsViewCount, skipCount, status, userId]);

  const jobsUrl = `${JOBS_URL}?${queryString({
    ...args,
  })}`;

  useRecoilQuery(
    jobsPollingStatusAtomFamily({ userId }),
    jobsPollingDataAtomFamily({ userId }),
    jobsUrl,
    fetcher,
    {
      refetchInterval: 2000,
      refetchOnWindowFocus: true,
      enabled: !!isAuthenticated,
    },
  );

  return { data: jobsPolling, status: jobsStatus };
};

export { useGetJobsPolling };
