import { api_ } from './axios';

export const DEFAULT_MAX_WIDTH = 5000;

export const removeOrientationAndResizeImage =
  async function removeOrientationAndResizeImage(
    imageFile: File,
    targetMaxWidth?: number,
  ) {
    const img = new Image();
    img.src = URL.createObjectURL(imageFile);

    async function transformImageFile() {
      await new Promise((resolve) => {
        img.onload = resolve;
      });

      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d') as CanvasRenderingContext2D;

      const aspectRatio = img.width / img.height;

      const exceedDefaultMaxWidth = img.width > DEFAULT_MAX_WIDTH;

      const exceedTargetMaxWidth = targetMaxWidth ? img.width > targetMaxWidth : false;

      const canvasWidth = targetMaxWidth
        ? exceedTargetMaxWidth
          ? targetMaxWidth
          : img.width
        : exceedDefaultMaxWidth
        ? DEFAULT_MAX_WIDTH
        : img.width;

      const canvasHeight = targetMaxWidth
        ? exceedTargetMaxWidth
          ? targetMaxWidth / aspectRatio
          : img.height
        : exceedDefaultMaxWidth
        ? DEFAULT_MAX_WIDTH / aspectRatio
        : img.height;

      canvas.width = canvasWidth;
      canvas.height = canvasHeight;

      const scale = Math.min(canvasWidth / img.width, canvasHeight / img.height);

      const scaledWidth = img.width * scale;
      const scaledHeight = img.height * scale;

      const x = (canvasWidth - scaledWidth) / 2;
      const y = (canvasHeight - scaledHeight) / 2;

      ctx.drawImage(img, x, y, scaledWidth, scaledHeight);

      const dataUrl = canvas.toDataURL(imageFile.type);
      const blob = await fetch(dataUrl).then((r) => r.blob());
      const retatedImageFile = new File([blob], imageFile.name, { type: imageFile.type });

      return retatedImageFile;
    }

    return transformImageFile();
  };

export const uploadImage = async (file: any, dtoken: any, maxWidth?: number) => {
  const role = dtoken.isAdmin() ? 'admin' : 'non-admin';
  try {
    if (!file) throw new Error('파일을 선택해 주세요!');
    const form = new FormData();
    const staticFile = await removeOrientationAndResizeImage(file, maxWidth);
    form.append('image', staticFile);
    form.append('body', JSON.stringify({ user: dtoken._id }));
    const { data: images_ } = await api_.post(`/${role}/image/file`, form);
    const image = images_[0];
    console.log(`upload success`);
    return image;
  } catch (error) {
    alert('업로드 실패');
    console.log(`upload fail: ${JSON.stringify((error as any).message)}`);
  }
};

export const uploadImageList = async (files: any, dtoken: any, maxWidth?: number) => {
  const role = dtoken.isAdmin() ? 'admin' : 'non-admin';
  try {
    if (!files) throw new Error('파일을 선택해 주세요!');
    const imageList: string[] = [];
    await Promise.all(
      files.map(async (file: any) => {
        const form = new FormData();
        const staticFile = await removeOrientationAndResizeImage(file, maxWidth);
        form.append('image', staticFile);
        form.append('body', JSON.stringify({ user: dtoken._id }));
        const { data: images_ } = await api_.post(`/${role}/image/file`, form);
        const image_ = images_[0];
        imageList.push(image_._id);
      }),
    );
    console.log(`upload success`);
    return imageList;
  } catch (error) {
    alert('업로드 실패');
    console.log(`upload fail: ${JSON.stringify((error as any).message)}`);
  }
};
