import { api_ } from '@plugin/axios';
import _ from 'lodash';
import { removeOrientationAndResizeImage } from '@plugin/uploadUtils';

import { Image } from './mongo/interfaces';

export type InputItemType = File | Image | null;
export type Images = { [x: number]: InputItemType };

export const createImagesApi = async (files: InputItemType[]) => {
  const images: Images = {}; // object id로 교체

  await Promise.all(
    files.map(async (file: InputItemType, idx: number) => {
      if (file === null) {
        images[idx] = null;
        return;
      }
      if (!(file instanceof File)) {
        images[idx] = file;
        return;
      }
      if (file instanceof File) {
        const form = new FormData();
        const staticFile = await removeOrientationAndResizeImage(file);
        form.append('image', staticFile);
        form.append('body', JSON.stringify({ name: file?.name }));
        const { data: image_ } = await api_.post('/general/image/file', form);
        images[idx] = image_;
      }
    }),
  );

  return images;
};

//* BatchJob
export const createImageArrayApi = async (files: FileList | never[]) => {
  const images: Image[] = await Promise.all(
    _.map(files, async (file) => {
      const form = new FormData();
      const staticFile = await removeOrientationAndResizeImage(file);
      form.append('image', staticFile);
      form.append('body', JSON.stringify({ name: file?.name }));
      const { data: image_ } = await api_.post('/general/image/file', form);
      return image_;
    }),
  );

  return images;
};
