import { useLayoutEffect, useState } from 'react';

import { useRecoilValue } from 'recoil';

import { AuthBodyBase } from '@api/mongo/controllers/auth.controller';
import { TextField } from '@components/TextField';
import { useAuth } from '@hooks/useAuth';
import { notificationInstanceAtom } from '@state/atom/notification.atom';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { notificateError } from 'src/@utils/noti.utils';
import { useAppLoading } from '@hooks/useAppLoading';

const USERNAME_LOCAL_STORAGE_KEY = 'username-nouvo';

type LoginForm = Pick<AuthBodyBase, 'username' | 'password'>;

const LoginContainer = () => {
  const navigate = useNavigate();
  const { loadingTryCatch } = useAppLoading();
  const [isSavedId, setIsSavedId] = useState(false);

  const notifiacationInstance = useRecoilValue(notificationInstanceAtom);
  const {
    register,
    handleSubmit,
    setValue,
    setFocus,
    formState: { errors },
  } = useForm<LoginForm>();
  const { loginByUsernamePassword } = useAuth();

  //초기 input 포커싱을 정해줌
  useLayoutEffect(() => {
    const savedUsername = localStorage.getItem(USERNAME_LOCAL_STORAGE_KEY);
    if (savedUsername) {
      setFocus('password');
    } else {
      setFocus('username');
    }
  }, [setFocus]);

  //저장된 아이디 불러옴
  useLayoutEffect(() => {
    const savedUsername = localStorage.getItem(USERNAME_LOCAL_STORAGE_KEY);
    if (savedUsername) {
      setValue('username', savedUsername);
      setIsSavedId(true);
    }
  }, [setValue]);

  const onSubmit = async (data: LoginForm) => {
    loadingTryCatch(async () => {
      //아이디 저장
      if (isSavedId) {
        localStorage.setItem(USERNAME_LOCAL_STORAGE_KEY, data.username);
      } else {
        localStorage.removeItem(USERNAME_LOCAL_STORAGE_KEY);
      }

      try {
        const token = await loginByUsernamePassword(data);
        if (token) {
          navigate('/job');
        }
      } catch (err) {
        notificateError(
          notifiacationInstance,
          '로그인에 실패하였습니다. 관리자에게 문의해 주세요.',
        );
        console.log(`err:`, err);
      }
    });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="w-[400px] space-y-4 rounded-md bg-gray-50 p-10 shadow-md">
        <TextField
          {...register('username', { required: true })}
          className=" bg-white px-2"
          placeholder="아이디"
          isError={!!errors.username}
          errorMessage="아이디를 확인해주세요."
        />
        <TextField
          type="password"
          autoComplete="off"
          {...register('password', { required: true })}
          className="bg-white px-2"
          placeholder="비밀번호"
          isError={!!errors.password}
          errorMessage="비밀번호를 입력해주세요."
        />

        <button className="w-full rounded-full bg-black py-4 text-16 font-semibold text-white">
          로그인
        </button>

        <div className="flex items-center">
          <input
            type="checkbox"
            id="recordId"
            checked={isSavedId}
            onChange={(e) => setIsSavedId(e.target.checked)}
          ></input>
          <label className="ml-1 cursor-pointer hover:font-bold" htmlFor="recordId">
            아이디 저장
          </label>
        </div>
      </div>
    </form>
  );
};
export { LoginContainer };
