import { FC, LabelHTMLAttributes } from 'react';

export const Label: FC<LabelHTMLAttributes<HTMLLabelElement>> = ({
  children,
  className = '',
  ...props
}) => {
  return (
    <label className={`label ${className}`} {...props}>
      {children}
    </label>
  );
};
