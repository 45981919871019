import { FC, HTMLAttributes, useCallback, useId, useState } from 'react';
import React from 'react';

import { useRecoilValue, useSetRecoilState } from 'recoil';

import { Icon } from '@components/Icon';
import { Job } from '@api/mongo/interfaces/job.interface';
import { JobStatus } from '@api/mongo/interfaces/status.interface';
import { notificateSuccess } from 'src/@utils/noti.utils';
import { notificationInstanceAtom } from '@state/atom/notification.atom';
import dayjs from 'dayjs';
import { Tooltip } from 'antd';
import { UserProfile } from '@components/UserProfile';

import { RunButton } from './RunButton';
import { jobCreateInputAtom } from '../state/JobContainer.atom';
import { downloadOutput } from '../utils/downloadOutputUtils';
import { OutputCardList } from './OutputCardList';
import { InputCardList } from './InputCardList';

export type JobRowProps = {
  job: Job;
  selected: boolean;
} & HTMLAttributes<HTMLDivElement>;

//* 결과 보여주기용으로 사용됨, 생성은 CreateJobRow
const JobRow: FC<JobRowProps> = ({ job, selected, className, ...props }) => {
  const uuid = useId();
  const checkBoxid = `checkbox-${uuid}`;

  const noti = useRecoilValue(notificationInstanceAtom);
  const [shouldReRunAnimate, setShouldReRunAnimate] = useState(false);
  const [shouldRunAnimate, setShouldRunAnimate] = useState(false);
  const setJobCreateInput = useSetRecoilState(jobCreateInputAtom);

  const runAnimate = useCallback(() => {
    //클릭마다 애니메이션 실행
    setShouldRunAnimate(false);
    requestAnimationFrame(() => {
      setShouldRunAnimate(true);
    });
  }, []);

  const jobCreatedAt = dayjs(job?.createdAt).format('YYYY-MM-DD HH:mm:ss');

  const outputDonwloadButtonHandler = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.stopPropagation();
      if (job.status === JobStatus.Done) {
        downloadOutput([job]);
      } else {
        alert('완료된 작업만 다운로드할 수 있습니다.');
      }
    },
    [job],
  );

  const reRunButtonHandler = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.stopPropagation();
      runAnimate();
      setJobCreateInput(() => {
        const dto = {
          inputName: job.input.name,
          jobName: job.name,
          optical_name: job.input.optical_name,
          source: job.input.source,
          target: job.input.target,
          optical: job.input.optical,
          card: job.input.card,
        };
        return dto;
      });
      notificateSuccess(noti, 'New Section에 등록되었습니다');
      return;
    },
    [job, noti, runAnimate, setJobCreateInput],
  );

  const JobStatusText = useCallback(() => {
    const setColor = () => {
      switch (job.status) {
        case JobStatus.Done: {
          return 'text-green-500';
        }
        case JobStatus.Error: {
          return 'text-red-500';
        }
        case JobStatus.Canceled: {
          return 'text-yellow-500';
        }
        default: {
          return 'text-gray-500';
        }
      }
    };
    return <p className={setColor()}>{job.status}</p>;
  }, [job.status]);

  return (
    <div
      className={`mx-auto w-full max-w-[95vw] cursor-pointer space-y-4 rounded-lg bg-white p-4 drop-shadow-lg hover:ring-2
      ${className}
        ${shouldReRunAnimate ? 'rerun-animation' : ''}
        ${shouldRunAnimate ? 'run-animation' : ''}
        ${selected ? 'ring-2 hover:ring-2' : ''}
        `}
      onAnimationEnd={() => {
        setShouldReRunAnimate(false);
        setShouldRunAnimate(false);
      }}
      {...props}
    >
      <div className="relative flex w-full justify-between pb-6 max-sm:flex-wrap">
        <div className="flex items-center gap-x-4 text-18">
          <p className="rounded-md bg-black px-2 py-1  font-semibold text-white">
            {job?.name ?? ''}
          </p>
          <p className="text-gray-500">{jobCreatedAt}</p>
          <JobStatusText />
        </div>

        {/* job status  */}
        <div className="mr-1 flex items-center gap-x-4 max-sm:flex-wrap">
          <p className="text-gray-500">jobId : {job._id}</p>
          <UserProfile />
          <RunButton jobStatus={JobStatus.Done} onClick={reRunButtonHandler} />
          <Tooltip title={`output 다운로드`}>
            <button
              type="button"
              onClick={outputDonwloadButtonHandler}
              className="group h-9 w-9 rounded-sm border-2 border-gray-200 transition-all center-box hover:border-blue-500 hover:bg-blue-500"
            >
              <Icon.Download1 className=" text-gray-400 group-hover:text-white" />
            </button>
          </Tooltip>
          <input
            id={checkBoxid}
            className="h-9 w-9 cursor-pointer"
            type="checkbox"
            onChange={() => {
              return;
            }}
            checked={selected}
          />
        </div>
      </div>
      {/* input & output IMG */}
      <div className="grid grid-cols-9 gap-x-8 max-sm:grid-cols-5">
        <InputCardList job={job} />
        <OutputCardList job={job} />
      </div>
    </div>
  );
};
export { JobRow };
