import React from 'react';

import { LoginContainer } from '@containers/LoginContainer';
import { Body } from '@components/Body';
const LoginPage = () => {
  return (
    <>
      <Body className="center-box">
        <LoginContainer />
      </Body>
    </>
  );
};
export { LoginPage };
