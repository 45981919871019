import { useCallback, useMemo } from 'react';

import { useRecoilState, useRecoilValue } from 'recoil';

import { User } from '@api/mongo/interfaces';
import { queryString } from '@hooks/recoil-query';
import { notificateError, notificateSuccess } from 'src/@utils/noti.utils';
import { api_ } from '@plugin/axios';
import { notificationInstanceAtom } from '@state/atom/notification.atom';
// import { CamelRole, LowerRole, UserRole } from 'src/interfaces';
import { AxiosError } from 'axios';
import _ from 'lodash';
import { tokenSelector, userProfileSelector } from '@state/atom/auth.atom';
import { AuthBodyBase, nestAuthApi } from '@api/mongo/controllers/auth.controller';

export interface GuestDto {
  name: string;
  phoneNumber: string;
  password: string;
}

export const useAuth = () => {
  //   const redirectAfterLogin = useRecoilValue(redirectAfterLoginAtom);
  const notifiacationInstance = useRecoilValue(notificationInstanceAtom);

  const [token, setToken] = useRecoilState(tokenSelector);
  //* authenticated 여부
  const guestAuthenticated = useMemo(() => {
    return !_.isNil(token);
  }, [token]);
  const [userProfile, setUserProfile] = useRecoilState(userProfileSelector);

  //   const userBugoBrand = useRecoilValue(userBugoBrandSelector);

  const isAuthenticated = guestAuthenticated;

  const notificationGenerator = useCallback(
    (err: AxiosError) => {
      let errMsgForNoti: string;

      switch (err.response?.status) {
        case 401:
          errMsgForNoti = '아이디 혹은 비밀번호(인증정보)를 잘못 입력하셨습니다.';
          break;
        case 403:
          errMsgForNoti = '해당 사이트에 접속할 권한이 없는 회원입니다.';
          break;
        default:
          errMsgForNoti =
            '알 수 없는 에러가 발생했습니다. 지속된다면 고객센터에 문의해주시기 바랍니다.';
      }
      // console.error(err);
      notificateError(notifiacationInstance, errMsgForNoti);
    },
    [notifiacationInstance],
  );
  const notificationBugoGuestGenerator = useCallback(
    (err: AxiosError) => {
      let errMsgForNoti: string;

      switch (err.response?.status) {
        case 401:
          errMsgForNoti = '해당 정보로 작성한 부고가 존재하지 않습니다.';
          break;
        case 403:
          errMsgForNoti = '해당 사이트에 접속할 권한이 없는 회원입니다.';
          break;
        default:
          errMsgForNoti =
            '알 수 없는 에러가 발생했습니다. 지속된다면 고객센터에 문의해주시기 바랍니다.';
      }
      // console.error(err);
      notificateError(notifiacationInstance, errMsgForNoti);
    },
    [notifiacationInstance],
  );

  // const dtoken = new DecodedToken(token);

  /** =======================================================
   * signup
   * ======================================================== */

  const signupByUsernamePasswordCheckPossible = async (
    body: Pick<AuthBodyBase, 'username'>,
  ) => {
    const { data } = await nestAuthApi().signupByUsernamePasswordCheckPossible(body);
    return data;
  };

  //   const signupByUsernamePassword = async (
  //     body: Pick<AuthBodyBase, 'username' | 'password' | 'roles' | 'info'>,
  //   ) => {
  //     try {
  //       const { data, status } = await nestAuthApi().signupByUsernamePassword(body);
  //       if (status !== 201) {
  //         notificateError(notifiacationInstance, JSON.stringify(data));
  //       }
  //       const { token } = data;
  //       console.log('token: ', token);
  //       if (token) setToken(token);
  //       const profile = (await nestAuthApi().getMe()).data;
  //       console.log('profile: ', profile);
  //       if (profile) {
  //         setUserProfile(profile);
  //         const patchMeResultProfile = await patchMe(body);
  //         patchMeResultProfile && setUserProfile(patchMeResultProfile);
  //       }
  //       return token;
  //     } catch (err) {
  //       console.error(err);
  //     }
  //   };

  //   const signupByKakaoCheckPossible = async (body: Pick<AuthBodyBase, 'code'>) => {
  //     const { data } = await nestAuthApi().signupByKakaoCheckPossible(body);
  //     return data;
  //   };

  //   //TODO: roles 입력받도록 (user info 입력도 받도록)
  //   const signupByKakao = async (body: Pick<AuthBodyBase, 'code' | 'roles'>) => {
  //     const {
  //       data: { token },
  //     } = await nestAuthApi().signupByKakao(body);
  //     if (token) setToken(token);
  //     const profile = (await nestAuthApi().getMe()).data;

  //     if (profile) {
  //       setUserProfile(profile);
  //     }
  //     return token;
  //   };

  /** =======================================================
   * login
   * ======================================================== */

  const loginByUsernamePassword = async (
    body: Pick<AuthBodyBase, 'username' | 'password'>,
  ) => {
    try {
      const {
        data: { token },
      } = await nestAuthApi().loginByUsernamePassword(body);

      if (token) setToken(token);
      console.log('token: ', token);

      const { data: profile } = await nestAuthApi().getMe();
      console.log('profile: ', profile);
      if (profile) {
        setUserProfile(profile);
      }
      notificateSuccess(notifiacationInstance, '로그인 되었습니다');

      return token;
    } catch (err) {
      const errObj = err as AxiosError;
      notificationGenerator(errObj);
      throw err;
    }
  };

  //   const confirmByUsernamePassword = async (
  //     body: Pick<AuthBodyBase, 'username' | 'password'>,
  //   ) => {
  //     try {
  //       const {
  //         data: { token },
  //       } = await nestAuthApi().loginByUsernamePassword(body);

  //       if (token) setToken(token);
  //       console.log('token: ', token);
  //       return token;
  //     } catch (err) {
  //       const errObj = err as AxiosError;
  //       notificationGenerator(errObj);
  //       throw err;
  //     }
  //   };

  //TODO ---
  //   const loginByKakao = async (data: any) => {
  //     const {
  //       data: { token },
  //     } = await api_.post('/auth/login/social/kakao', data);
  //     if (token) setToken(token);
  //     const { data: profile } = await nestAuthApi().getMe();
  //     if (profile) {
  //       setUserProfile(profile);
  //     }
  //     return token;
  //   };

  /** =======================================================
   * signup-or-login
   * ======================================================== */
  //   const signupOrLoginByKakao = async ({
  //     code,
  //     roles,
  //   }: {
  //     code: string;
  //     roles: UserRole[];
  //   }) => {
  //     try {
  //       const {
  //         data: { token },
  //       } = await api_.post('/auth/signup-or-login/social/kakao', { code, roles });
  //       if (token) setToken(token);
  //       const { data: profile } = await nestAuthApi().getMe();
  //       if (profile) {
  //         setUserProfile(profile);
  //       }
  //       notificateSuccess(notifiacationInstance, '로그인 되었습니다');
  //       return token;
  //     } catch (err) {
  //       const errObj = err as AxiosError;
  //       notificationGenerator(errObj);
  //       throw err;
  //     }
  //   };

  /** =======================================================
   * user api
   * ======================================================== */
  //   const linkByKakao = async (dto: { code: string }) => {
  //     if (!token) return;
  //     try {
  //       const { data } = await api_.patch<User>(`/auth/link/social/kakao`, dto);
  //       return data;
  //     } catch (error: any) {
  //       console.error(error);
  //       console.error(error?.response?.data);
  //     }
  //   };

  const getMe = async () => {
    if (!token) return;
    try {
      const { data } = await api_.get<User>(`auth/me/`);
      return data;
    } catch (error: any) {
      console.error(error);
      console.error(error?.response?.data);
    }
  };

  //   const patchMe = async (dto: Pick<User, 'info' | 'adminDetail'>) => {
  //     // if (!token) return;
  //     try {
  //       const { data } = await api_.patch<User>(`/auth/me/detail`, dto);
  //       return data;
  //     } catch (error: any) {
  //       console.error(error);
  //       console.error(error?.response?.data);
  //     }
  //   };

  //! 비밀번호 변경
  //   const patchMePassword = async (newPassword: string) => {
  //     try {
  //       await api_.patch('/auth/me/password', { password: newPassword });
  //       notificateSuccess(notifiacationInstance, '비밀번호 변경이 완료되었습니다.');
  //       // await logout();
  //     } catch (err) {
  //       notificateError(notifiacationInstance, '비밀번호 변경에 실패했습니다.');
  //       console.error(err);
  //     }
  //   };

  //   const refreshUserProfile = async () => {
  //     if (token) {
  //       const userProfile = await getMe(token);
  //       if (userProfile) {
  //         setUserProfile(userProfile);
  //       } else {
  //         // console.log('redirect 해야함');
  //       }
  //     }
  //   };

  /** =======================================================
   * link
   * ======================================================== */

  /** =======================================================
   * utils
   * ======================================================== */

  //   const roles = userProfile?.roles;

  //   const isAdmin = () => {
  //     if (roles) {
  //       return roles.includes(UserRole.Admin);
  //     } else {
  //       return false;
  //     }
  //   };

  //   const isBugoFuneralHome = () => {
  //     if (roles) {
  //       return roles.includes(UserRole.BugoFuneralHome);
  //     } else {
  //       return false;
  //     }
  //   };

  //   const assetRole = () => {
  //     if (roles) {
  //       if (roles.includes(UserRole.Admin)) {
  //         return LowerRole.Admin;
  //       } else {
  //         return LowerRole.NonAdmin;
  //       }
  //     } else {
  //       return LowerRole.NonAdmin;
  //     }
  //   };

  const logout = async () => {
    setToken(null);
    setUserProfile(null);
    // window.location.reload();
  };

  //   const apiLogout = async () => {
  //     setToken(null);
  //     setUserProfile(null);
  //     window.location.reload();
  //   };

  return {
    // roles,
    token,
    guestAuthenticated,
    isAuthenticated,
    // isGuest,
    // userProfile,
    // redirectAfterLogin,
    signupByUsernamePasswordCheckPossible,
    // signupByUsernamePassword,
    // signupByKakaoCheckPossible,
    // confirmByUsernamePassword,
    loginByUsernamePassword,
    // loginByKakao,
    // linkByKakao,
    // patchMe,
    getMe,
    // patchMePassword,
    // signupOrLoginStoreGuest,
    // signupOrLoginBugoGuest,
    // signupOrLoginByKakao,
    // signupByKakao,
    // isAdmin,
    // isBugoFuneralHome,
    // assetRole,
    // refreshUserProfile,
    logout,
    // apiLogout,
  };
};
