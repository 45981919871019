import { FC, useEffect } from 'react';

import { useSetRecoilState } from 'recoil';

import {
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
} from 'react-router-dom';
import { RootRouter } from '@route';
import { notificationInstanceAtom } from '@state/atom/notification.atom';
import { notification } from 'antd';

const App: FC = () => {
  const [api, contextHolder] = notification.useNotification();
  const setNotificationInstance = useSetRecoilState(notificationInstanceAtom);

  const router = createBrowserRouter(
    createRoutesFromElements(<Route path="/*" element={<RootRouter />} />),
  );

  useEffect(() => {
    setNotificationInstance(api);
  }, [api, setNotificationInstance]);

  return (
    <>
      {contextHolder}
      <div className="App bg-gray-100">
        <RouterProvider router={router} />
      </div>
    </>
  );
};

export default App;
