import { FC, useCallback, useEffect, useState } from 'react';

import { useSetRecoilState } from 'recoil';

import { TextField } from '@components/TextField';
import { useForm } from 'react-hook-form';
import { Select } from 'antd';
import { ConfirmModal } from '@components/Modal/ConfirmModal';
import { modalOpenAtomFamily } from '@state/atom/openAtom';
import { RunButton } from '@containers/JobContainer/components/RunButton';
import Modal from '@components/Modal/Modal';
import { Icon } from '@components/Icon';
import { useNavigate } from 'react-router-dom';
import { UserProfile } from '@components/UserProfile';

import { MultipleImageInput } from './MultipleImageInput';
import { useCreateBatchJob } from '../hooks/useCreateBatchJob';

const CreateBatchJobSection: FC = () => {
  const navigate = useNavigate();
  const { register, getValues } = useForm();
  const [mode, setMode] = useState(2);

  const confirmModalId = 'confirm-modal';
  const validModalId = 'valid-modal';
  const successModalId = 'success-modal';

  const {
    sourceRef,
    targetRef,
    opticalRef,
    cardRef,
    excuteCreateBatchJob,
    validateRunMode,
  } = useCreateBatchJob({
    mode,
    getValues,
    confirmModalId,
    validModalId,
    successModalId,
  });

  const setConfirmModal = useSetRecoilState(modalOpenAtomFamily(confirmModalId));
  const setValidModal = useSetRecoilState(modalOpenAtomFamily(validModalId));
  const setSuccessModal = useSetRecoilState(modalOpenAtomFamily(successModalId));

  const JobConfirmModal = () => {
    return (
      <ConfirmModal
        title={`BatchJob - mode#${mode}`}
        secondaryButtonText="취소"
        confirmButtonText="실행"
        showSecondaryButton
        onClick={excuteCreateBatchJob}
        openId={confirmModalId}
      >
        <p>실행 하시겠습니까?</p>
      </ConfirmModal>
    );
  };

  const ValidErrorModal = useCallback(() => {
    return (
      <Modal
        openId={validModalId}
        className="bg-white px-6 py-10"
        position="center"
        closeButton={true}
      >
        <div className="space-y-4 text-18">
          <div className="flex items-center gap-x-2">
            <Icon.Exclamation className="h-10 w-10 text-red-600" aria-hidden="true" />
            <p className="text-24 font-semibold">Mode#{mode} Error</p>
          </div>
          <p>
            현재 모드와 첨부한 이미지의 조건이 맞지않습니다. <br />
            이미지와 조건을 확인해주세요!
          </p>
          <div className="text-red-500">
            {mode === 1 ? (
              <p>* mode#1: target, optical 이미지 갯수가 동일해야합니다.</p>
            ) : (
              <p>* mode#2: source, target, optical 이미지 갯수가 동일해야합니다.</p>
            )}
          </div>
        </div>
      </Modal>
    );
  }, [mode]);

  const SuccessModal = useCallback(() => {
    return (
      <Modal
        openId={successModalId}
        className="bg-white px-6 py-10"
        position="center"
        closeButton
      >
        <div className="space-y-4 text-18">
          <div className="flex items-center gap-x-2">
            <Icon.CheckCircle className="h-10 w-10 fill-green-500" aria-hidden="true" />
            <p className="text-24 font-semibold">Mode#{mode} Success</p>
          </div>
          <button
            className=" w-full rounded-full bg-black px-1 py-2 text-white  transition-all hover:bg-gray-500"
            onClick={() => {
              navigate('/job');
            }}
          >
            Jobs History에서 확인하기
          </button>
        </div>
      </Modal>
    );
  }, [mode, navigate]);

  const ModeSelector = useCallback(() => {
    return (
      <div className="flex items-center">
        <p className="text-20 font-semibold ">Mode :&nbsp;</p>
        <Select
          className="min-w-[60px] text-center"
          value={mode}
          onChange={(value) => {
            if (value === 1) {
              alert('현재 준비중인 옵션입니다.');
              return;
            }
            setMode(value);
          }}
          options={[
            { value: 1, label: '1' },
            { value: 2, label: '2' },
          ]}
        />
      </div>
    );
  }, [mode]);

  useEffect(() => {
    return () => {
      setSuccessModal(false);
      setValidModal(false);
      setConfirmModal(false);
    };
  }, [setConfirmModal, setSuccessModal, setValidModal]);

  return (
    <div className="p-10">
      <p className="pb-2 text-24 font-semibold">Create BatchJob</p>
      <div className="space-y-4 rounded-md bg-white p-4 shadow-md">
        <div className="flex justify-between">
          <div>
            <TextField
              tabIndex={2}
              placeholder="SuperJob Name"
              className="bg-gray-100 px-4"
              {...register('superJobName', {})}
            />
          </div>

          <div className="flex items-center gap-x-4">
            <UserProfile />
            <ModeSelector />
            <RunButton
              onClick={(e) => {
                e.stopPropagation();
                validateRunMode();
              }}
            />
          </div>
        </div>
        <div className="w-full rounded-md bg-gray-100 p-4">
          <MultipleImageInput
            ref={sourceRef}
            label="source"
            showIndex
            modify
            previewBoxClassName="w-[120px] h-[120px]"
          />
        </div>
        <div className="w-full rounded-md bg-gray-100 p-4">
          <MultipleImageInput
            ref={targetRef}
            label="target"
            showIndex
            modify
            previewBoxClassName="w-[120px] h-[120px]"
          />
        </div>
        <div className="w-full rounded-md bg-gray-100 p-4">
          <MultipleImageInput
            ref={opticalRef}
            label="optical"
            showIndex
            modify
            previewWrapperClassName="flex h-full w-full gap-x-4 overflow-x-auto pb-0.5"
            previewBoxClassName="w-[120px] h-[120px]"
            previewFooterNode={(url) => {
              return (
                <TextField
                  placeholder="Optical Name"
                  className="mt-2 bg-white px-2"
                  wrapperClassName="w-[120px]"
                  {...register(`optical_name-${url}`, {})}
                />
              );
            }}
          />
        </div>
        <div className="w-full rounded-md bg-gray-100 p-4">
          <div className="flex h-full gap-x-4">
            <MultipleImageInput
              ref={cardRef}
              multiple={false}
              label="card"
              showIndex
              modify
              previewBoxClassName="w-[120px] h-[120px]"
            />
            <div className="space-y-4 rounded-md border bg-white p-2">
              <p className="py-1">Text Data</p>
              <TextField
                placeholder="성함 입력"
                className="bg-gray-100 px-4"
                {...register('inputName', {})}
              />
            </div>
          </div>
        </div>
      </div>
      <JobConfirmModal />
      <ValidErrorModal />
      <SuccessModal />
    </div>
  );
};
export { CreateBatchJobSection };
