import { FC, HTMLAttributes } from 'react';

import { JobStatus } from '@api/mongo/interfaces/status.interface';

interface RunButtonProps extends HTMLAttributes<HTMLButtonElement> {
  jobStatus?: JobStatus;
}

const RunButton: FC<RunButtonProps> = ({ jobStatus, ...props }) => {
  const setStyle = () => {
    if (jobStatus) {
      return 'h-fit min-w-[80px] rounded-md border px-3 py-1 text-20 font-semibold shadow-sm hover:bg-black hover:text-white transition-all';
    } else {
      return 'h-fit min-w-[80px] rounded-md border px-3 py-1 text-20 font-semibold shadow-sm bg-black text-white hover:bg-gray-500 transition-all';
    }
  };

  return (
    <button {...props} type="button" className={`${setStyle()}`}>
      {setBtnText(jobStatus)}
    </button>
  );
};
export { RunButton };

const setBtnText = (jobStatus?: JobStatus) => {
  switch (jobStatus) {
    case JobStatus.Todo: {
      return 'Todo';
    }
    case JobStatus.Doing: {
      return 'Doing';
    }
    case JobStatus.Done: {
      return 'Re-Run';
    }
    default: {
      return 'Run';
    }
  }
};
