import { Icon } from '@components/Icon';

// 우측 하단에 생성되는 스크롤투탑 버튼
const ScrollToTopButton = () => {
  return (
    <div
      className="fixed bottom-10 right-5 z-10 cursor-pointer rounded-full bg-black p-3 shadow"
      onClick={() => {
        document.documentElement.scrollTo({ top: 0 });
      }}
    >
      <Icon.Arrow1 className="h-7 w-7 rotate-90 fill-white" />
    </div>
  );
};

export { ScrollToTopButton };
