import { Body } from '@components/Body';
import { BatchJobContainer } from '@containers/BatchJobContainer';
import { HeaderContainer } from '@containers/HeaderContainer';
const BatchJobPage = () => {
  return (
    <>
      <HeaderContainer />
      <Body>
        <BatchJobContainer />
      </Body>
    </>
  );
};
export { BatchJobPage };
