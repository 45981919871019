export enum StatusBase {
  Todo = 'Todo',
  Doing = 'Doing',
  Done = 'Done',
}

export enum JobStatus {
  Todo = 'Todo',
  Doing = 'Doing',
  Done = 'Done',
  Canceled = 'Canceled',
  Rejected = 'Rejected',
  Failed = 'Failed',
  Error = 'Error',
}

export enum WebsocketStatus {
  Connect = 'connect',
  Disconnect = 'disconnect',
}
