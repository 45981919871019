import { ComponentType, FC, SVGProps } from 'react';

import { ReactComponent as AddProduct } from '../assets/icon/icon-add-product.svg';
import { ReactComponent as Alarm } from '../assets/icon/icon-alarm.svg';
import { ReactComponent as Arrow1 } from '../assets/icon/icon-arrow-1.svg';
import { ReactComponent as ArrowBackward } from '../assets/icon/icon-arrow-backward.svg';
import { ReactComponent as Badge } from '../assets/icon/icon-badge.svg';
import { ReactComponent as BadgeActive } from '../assets/icon/icon-badge-active.svg';
import { ReactComponent as BadgeDisabled } from '../assets/icon/icon-badge-disabled.svg';
import { ReactComponent as Bang } from '../assets/icon/icon-bang.svg';
import { ReactComponent as Box } from '../assets/icon/icon-box.svg';
import { ReactComponent as BoxActive } from '../assets/icon/icon-box-active.svg';
import { ReactComponent as BoxDisabled } from '../assets/icon/icon-box-disabled.svg';
import { ReactComponent as Bucket } from '../assets/icon/icon-bucket.svg';
import { ReactComponent as Building } from '../assets/icon/icon-building.svg';
import { ReactComponent as Calendar } from '../assets/icon/icon-calendar.svg';
import { ReactComponent as CalendarCheck } from '../assets/icon/icon-calendar-check.svg';
import { ReactComponent as CaretUp } from '../assets/icon/icon-caret-up.svg';
import { ReactComponent as Category } from '../assets/icon/icon-category.svg';
import { ReactComponent as Check } from '../assets/icon/icon-check.svg';
import { ReactComponent as CheckCircle } from '../assets/icon/icon-check-circle.svg';
import { ReactComponent as Clock } from '../assets/icon/icon-clock.svg';
import { ReactComponent as Close } from '../assets/icon/icon-close.svg';
import { ReactComponent as CloseLg } from '../assets/icon/icon-close-lg.svg';
import { ReactComponent as Count } from '../assets/icon/icon-count.svg';
import { ReactComponent as Custom } from '../assets/icon/icon-custom.svg';
import { ReactComponent as Document } from '../assets/icon/icon-document.svg';
import { ReactComponent as Download } from '../assets/icon/icon-download.svg';
import { ReactComponent as Download1 } from '../assets/icon/icon-download-1.svg';
import { ReactComponent as DownloadNavy } from '../assets/icon/icon-download-navy.svg';
import { ReactComponent as Down } from '../assets/icon/icon-down.svg';
import { ReactComponent as Edit } from '../assets/icon/icon-edit.svg';
import { ReactComponent as Enquiry } from '../assets/icon/icon-enquiry.svg';
import { ReactComponent as Exclamation } from '../assets/icon/icon-exclamation.svg';
import { ReactComponent as Expand } from '../assets/icon/icon-expand.svg';
import { ReactComponent as Eye } from '../assets/icon/icon-eye.svg';
import { ReactComponent as EyeLine } from '../assets/icon/icon-eye-line.svg';
import { ReactComponent as Heart1 } from '../assets/icon/icon-heart-1.svg';
import { ReactComponent as HeartDefault } from '../assets/icon/icon-heart-default.svg';
import { ReactComponent as HeartGray } from '../assets/icon/icon-heart-gray.svg';
import { ReactComponent as HeartRed } from '../assets/icon/icon-heart-red.svg';
import { ReactComponent as Image } from '../assets/icon/icon-image.svg';
import { ReactComponent as Image2 } from '../assets/icon/icon-image-2.svg';
import { ReactComponent as IMG } from '../assets/icon/icon-IMG.svg';
import { ReactComponent as Info } from '../assets/icon/icon-info.svg';
import { ReactComponent as KaKao } from '../assets/icon/icon-kakao.svg';
import { ReactComponent as Location } from '../assets/icon/icon-location.svg';
import { ReactComponent as Logo } from '../assets/icon/icon-logo.svg';
import { ReactComponent as Logout } from '../assets/icon/icon-logout.svg';
import { ReactComponent as LogoutMono } from '../assets/icon/icon-logout-mono.svg';
import { ReactComponent as Map } from '../assets/icon/icon-map.svg';
import { ReactComponent as MapActive } from '../assets/icon/icon-map-active.svg';
import { ReactComponent as MyLocation } from '../assets/icon/icon-my-location.svg';
import { ReactComponent as Menu } from '../assets/icon/icon-menu.svg';
import { ReactComponent as Message } from '../assets/icon/icon-message.svg';
import { ReactComponent as Messages } from '../assets/icon/icon-messages.svg';
import { ReactComponent as Note } from '../assets/icon/icon-note.svg';
import { ReactComponent as Receipt } from '../assets/icon/icon-receipt.svg';
import { ReactComponent as Package } from '../assets/icon/icon-package.svg';
import { ReactComponent as Person } from '../assets/icon/icon-person.svg';
import { ReactComponent as PersonDisabled } from '../assets/icon/icon-person-disabled.svg';
import { ReactComponent as PersonActive } from '../assets/icon/icon-person-active.svg';
import { ReactComponent as PetName } from '../assets/icon/icon-pet-name.svg';
import { ReactComponent as Photos } from '../assets/icon/icon-photos.svg';
import { ReactComponent as Plus } from '../assets/icon/icon-plus.svg';
import { ReactComponent as Profile } from '../assets/icon/icon-profile.svg';
import { ReactComponent as Schedule } from '../assets/icon/icon-schedule.svg';
import { ReactComponent as Search1 } from '../assets/icon/icon-search-1.svg';
import { ReactComponent as Search2 } from '../assets/icon/icon-search-2.svg';
import { ReactComponent as SearchActive } from '../assets/icon/icon-search-active.svg';
import { ReactComponent as SearchBuilding } from '../assets/icon/icon-search-building.svg';
import { ReactComponent as Share } from '../assets/icon/icon-share.svg';
import { ReactComponent as Sort } from '../assets/icon/icon-sort.svg';
import { ReactComponent as Star1 } from '../assets/icon/icon-star-1.svg';
import { ReactComponent as Star2 } from '../assets/icon/icon-star-2.svg';
import { ReactComponent as Ticket } from '../assets/icon/icon-ticket.svg';
import { ReactComponent as Trash } from '../assets/icon/icon-trash.svg';
import { ReactComponent as Truck } from '../assets/icon/icon-truck.svg';
import { ReactComponent as Tune } from '../assets/icon/icon-tune.svg';
import { ReactComponent as User } from '../assets/icon/icon-user.svg';
import { ReactComponent as UserLight } from '../assets/icon/icon-user-light.svg';
import { ReactComponent as UserMono } from '../assets/icon/icon-user-mono.svg';

function convertToComponent<T extends SVGProps<SVGSVGElement>>(
  Icon: ComponentType<T>,
): FC<T> {
  return ({ className = 'fill-black', ...props }) => (
    <Icon className={` ${className}`} {...(props as T)} />
  );
}
const Icon = {
  AddProduct: convertToComponent(AddProduct),
  Alarm: convertToComponent(Alarm),
  Arrow1: convertToComponent(Arrow1),
  ArrowBackward: convertToComponent(ArrowBackward),
  Badge: convertToComponent(Badge),
  BadgeActive: convertToComponent(BadgeActive),
  BadgeDisabled: convertToComponent(BadgeDisabled),
  Bang: convertToComponent(Bang),
  Box: convertToComponent(Box),
  BoxActive: convertToComponent(BoxActive),
  BoxDisabled: convertToComponent(BoxDisabled),
  Bucket: convertToComponent(Bucket),
  Building: convertToComponent(Building),
  Calendar: convertToComponent(Calendar),
  CalendarCheck: convertToComponent(CalendarCheck),
  Category: convertToComponent(Category),
  CaretUp: convertToComponent(CaretUp),
  Check: convertToComponent(Check),
  CheckCircle: convertToComponent(CheckCircle),
  Clock: convertToComponent(Clock),
  Close: convertToComponent(Close),
  CloseLg: convertToComponent(CloseLg),
  Count: convertToComponent(Count),
  Custom: convertToComponent(Custom),
  Document: convertToComponent(Document),
  Download: convertToComponent(Download),
  Download1: convertToComponent(Download1),
  DownloadNavy: convertToComponent(DownloadNavy),
  Down: convertToComponent(Down),
  Edit: convertToComponent(Edit),
  Enquiry: convertToComponent(Enquiry),
  Exclamation: convertToComponent(Exclamation),
  Expand: convertToComponent(Expand),
  Eye: convertToComponent(Eye),
  EyeLine: convertToComponent(EyeLine),
  Heart1: convertToComponent(Heart1),
  HeartGray: convertToComponent(HeartGray),
  HeartRed: convertToComponent(HeartRed),
  HeartDefault: convertToComponent(HeartDefault),
  Image: convertToComponent(Image),
  Image2: convertToComponent(Image2),
  IMG: convertToComponent(IMG),
  Info: convertToComponent(Info),
  KaKao: convertToComponent(KaKao),
  Location: convertToComponent(Location),
  Logo: convertToComponent(Logo),
  Logout: convertToComponent(Logout),
  LogoutMono: convertToComponent(LogoutMono),
  Map: convertToComponent(Map),
  MapActive: convertToComponent(MapActive),
  MyLocation: convertToComponent(MyLocation),
  Menu: convertToComponent(Menu),
  Message: convertToComponent(Message),
  Messages: convertToComponent(Messages),
  Note: convertToComponent(Note),
  Receipt: convertToComponent(Receipt),
  Package: convertToComponent(Package),
  Person: convertToComponent(Person),
  PersonDisabled: convertToComponent(PersonDisabled),
  PersonActive: convertToComponent(PersonActive),
  PetName: convertToComponent(PetName),
  Photos: convertToComponent(Photos),
  Plus: convertToComponent(Plus),
  Profile: convertToComponent(Profile),
  Schedule: convertToComponent(Schedule),
  Search1: convertToComponent(Search1),
  Search2: convertToComponent(Search2),
  SearchActive: convertToComponent(SearchActive),
  SearchBuilding: convertToComponent(SearchBuilding),
  Share: convertToComponent(Share),
  Sort: convertToComponent(Sort),
  Star1: convertToComponent(Star1),
  Star2: convertToComponent(Star2),
  Ticket: convertToComponent(Ticket),
  Trash: convertToComponent(Trash),
  Truck: convertToComponent(Truck),
  Tune: convertToComponent(Tune),
  User: convertToComponent(User),
  UserLight: convertToComponent(UserLight),
  UserMono: convertToComponent(UserMono),
};

export { Icon };
