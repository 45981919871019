import React, { FC, useCallback } from 'react';

import { useRecoilState } from 'recoil';

import { TextField } from '@components/TextField';
import { fileBucketUrl } from '@plugin/s3Image';
import { MultipleImageInput } from '@containers/BatchJobContainer/components/MultipleImageInput';
import { Tooltip } from 'antd';
import { UseFormRegister } from 'react-hook-form';
import { InputImages, JobCreateInput } from '@api/mongo/interfaces/job.interface';

import { capitalizeFirstLetter } from '../utils/capitalizeFirstLetter';
import { INPUT_FIELD } from './InputCardList';
import { jobCreateInputAtom } from '../state/JobContainer.atom';

//jobInput 생성용, 공용컴포넌트 사용 시 jobInputAtom을 불필요하게 구독하게되어 분리함
export type InputCardListProps = {
  register: UseFormRegister<JobCreateInput>;
};
const CreateInputCardList: FC<InputCardListProps> = React.memo(({ register }) => {
  const [jobInput, setJobInput] = useRecoilState(jobCreateInputAtom);

  const setJobItem = useCallback(
    (fileList: FileList, item: InputImages) => {
      setJobInput((prev) => {
        const update = { ...prev, [item]: fileList[0] };
        return update;
      });
    },
    [setJobInput],
  );

  const InputCards = useCallback(() => {
    return INPUT_FIELD.map((item) => {
      let imgUrlTest: string | undefined;
      const fileOrImage = jobInput[item];
      let isUploaded = false;
      if (fileOrImage) {
        if ('key' in fileOrImage) {
          // Image 타입인 경우
          imgUrlTest = fileBucketUrl(fileOrImage.key);
          isUploaded = true;
        } else if (fileOrImage instanceof File) {
          // File 타입인 경우
          imgUrlTest = URL.createObjectURL(fileOrImage);
          isUploaded = false;
        }
      }

      return (
        <div className="relative rounded-md border bg-white p-2" key={item}>
          {isUploaded ? (
            <Tooltip title="업로드가 완료된 상태입니다.">
              <div className="absolute right-2 top-3 h-2 w-2 rounded-full bg-green-500"></div>
            </Tooltip>
          ) : (
            <Tooltip title="업로드 예정 상태입니다.">
              <div className="absolute right-2 top-3 h-2 w-2 rounded-full bg-red-500"></div>
            </Tooltip>
          )}
          <MultipleImageInput
            modify
            multiple={false}
            previewBoxClassName="h-full"
            labelNode={<p>{capitalizeFirstLetter(item)}</p>}
            defaultUrls={imgUrlTest ? [imgUrlTest] : []}
            setImage={(fileList) => {
              setJobItem(fileList, item);
            }}
          />
        </div>
      );
    });
  }, [jobInput, setJobItem]);

  return (
    <div className="relative col-span-5 grid grid-cols-5 gap-x-2 rounded-md bg-gray-100 p-2">
      <div className="absolute -top-7 left-2 z-10 text-18 font-medium text-gray-400">
        Input
      </div>
      <InputCards />
      <div className="space-y-2 rounded-md border bg-white p-2">
        <p className="py-1">Text Data</p>
        <TextField
          tabIndex={1}
          id={'inputName'}
          placeholder="성함 입력"
          className="bg-gray-100 px-4"
          {...register('inputName', {})}
        />
        <TextField
          tabIndex={2}
          id={'optical_name'}
          placeholder="OpticalName"
          className="bg-gray-100 px-4"
          {...register('optical_name', {})}
        />
      </div>
    </div>
  );
});
export { CreateInputCardList };
