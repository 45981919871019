import React from 'react';

import { ToyContainer } from '@containers/ToyContainer';

const ToyPage = () => {
  return (
    <div>
      <ToyContainer />
    </div>
  );
};

export { ToyPage };
