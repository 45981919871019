import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import React from 'react';

import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import FuzzySearch from 'fuzzy-search';
import { TextField } from '@components/TextField';
import { Button, Pagination } from 'antd';
import { Icon } from '@components/Icon';
import { useQueryClient } from 'react-query';
import DismissModal from '@components/Modal/DismissModal';
import { modalOpenAtomFamily } from '@state/atom/openAtom';
import { LoadingSpinnerAntd } from '@components/LoadingSpinnerAntd';
import { useSearchParam } from 'react-use';
import { queryString } from '@hooks';
import { api_ } from '@plugin/axios';
import { useAppLoading } from '@hooks/useAppLoading';
import { JobStatus } from '@api/mongo/interfaces/status.interface';
import { Job } from '@api/mongo/interfaces/job.interface';
import { userProfileSelector } from '@state/atom/auth.atom';

import { JobRow } from './components/JobRow';
import { jobsUrl } from './state/jobContainer.hook';
import {
  defaultJobCreateInputValue,
  deleteSelectedJobsAtom,
  jobCreateInputAtom,
  jobsCurrentPageAtom,
} from './state/JobContainer.atom';
import { downloadOutput } from './utils/downloadOutputUtils';
import { CreateJobRow } from './components/CreateJobRow';
import { JOBS_URL, useGetJobsPolling } from './hooks/useGetJobsPolling';
import { NoDelayButton } from './components/NoDelayButton';
import { JobsViewCountSelector } from './components/JobsViewCountSelector';
import { ScrollToTopButton } from './components/ScrollToTopButton';
import { useGetJobsPageCount } from './hooks/useGetJobsPageCount';
import { JobRowMobile } from './components/JobRowMobile';

const JobContainer: FC = React.memo(() => {
  const { totalPages } = useGetJobsPageCount();
  const [currentPage, setCurrentPage] = useRecoilState(jobsCurrentPageAtom);
  const [searchValue, setSearchValue] = useState<string>('');

  const { loadingTryCatch } = useAppLoading();
  const queryClient = useQueryClient();
  const paramStatus = useSearchParam('status');
  const userId = useRecoilValue(userProfileSelector)?._id ?? '';
  const containerRef = useRef<HTMLDivElement>(null);

  //! 기존 소켓 방식 주석처리
  const { data: jobsPolling, status: jobsPollingStatus } = useGetJobsPolling({ userId });

  const setJobCreateInput = useSetRecoilState(jobCreateInputAtom);

  // FuzzySearch
  const searcher = new FuzzySearch(jobsPolling ?? [], ['name']);
  const resultJobs = searcher.search(searchValue);

  const onChangeSearchHandler = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setSearchValue(event.currentTarget.value);
    },
    [],
  );

  //* page set
  const pageChagneEventHandler = (page: number) => {
    setCurrentPage(page);
  };

  //* 삭제 모달
  const deleteJobsModalId = 'delete-jobs-modal';
  const setDeleteJobsModal = useSetRecoilState(modalOpenAtomFamily(deleteJobsModalId));
  const [deleteSelectedJobs, setDeleteSelectedJobs] =
    useRecoilState(deleteSelectedJobsAtom);
  const isSelectedDeleteJob = deleteSelectedJobs.length > 0;

  //* 삭제 모달 오픈
  const deleteJobsButtonHandler = useCallback(() => {
    setDeleteJobsModal(true);
  }, [setDeleteJobsModal]);

  //* 삭제 요청
  const excuteDeleteJobs = useCallback(async () => {
    loadingTryCatch(async () => {
      try {
        const deleteQuery = queryString({
          filter: {
            _id: {
              $in: deleteSelectedJobs.map((job) => job._id),
            },
          },
        });
        await api_.delete(`${JOBS_URL}?${deleteQuery}`);
        await queryClient.invalidateQueries(jobsUrl(userId));
        setDeleteSelectedJobs([]);
      } catch (err) {
        console.log(`deleteJobs err :`, err);
      }
    });
  }, [deleteSelectedJobs, loadingTryCatch, queryClient, setDeleteSelectedJobs, userId]);

  const allSelectButtonHandler = useCallback(() => {
    if (deleteSelectedJobs.length > 0) {
      setDeleteSelectedJobs([]);
      return;
    }
    setDeleteSelectedJobs(resultJobs);
  }, [deleteSelectedJobs, resultJobs, setDeleteSelectedJobs]);

  const outputsDowload = () => {
    //done만 다운
    const doneJobs = deleteSelectedJobs.filter((job) => job.status === JobStatus.Done);
    downloadOutput(doneJobs);
  };

  const handleCheckboxToggle = useCallback(
    (currJob: Job) => {
      if (deleteSelectedJobs.includes(currJob)) {
        setDeleteSelectedJobs(
          deleteSelectedJobs.filter((job) => job._id !== currJob._id),
        );
      } else {
        setDeleteSelectedJobs([...deleteSelectedJobs, currJob]);
      }
    },
    [deleteSelectedJobs, setDeleteSelectedJobs],
  );

  const JobRows = useMemo(() => {
    return (
      jobsPollingStatus === 'success' &&
      resultJobs?.map((job) => {
        const isSelectedDeleteJob = deleteSelectedJobs.includes(job);
        return (
          <React.Fragment key={job._id}>
            <JobRowMobile
              className="hidden max-sm:block"
              job={job}
              selected={isSelectedDeleteJob}
              onClick={() => {
                handleCheckboxToggle(job);
              }}
            />
            <JobRow
              className="block max-sm:hidden"
              job={job}
              selected={isSelectedDeleteJob}
              onClick={() => {
                handleCheckboxToggle(job);
              }}
            />
          </React.Fragment>
        );
      })
    );
  }, [deleteSelectedJobs, handleCheckboxToggle, jobsPollingStatus, resultJobs]);

  //삭제 목록, reRunAtom 초기화
  useEffect(() => {
    return () => {
      setJobCreateInput(defaultJobCreateInputValue);
      setDeleteSelectedJobs([]);
    };
  }, [setDeleteSelectedJobs, setJobCreateInput]);

  return (
    <div ref={containerRef}>
      {/* header */}
      <div className="sticky left-0 top-0 z-10 flex h-fit w-full justify-end bg-gray-100 pb-4 pr-10 pt-6 max-sm:top-[58px]">
        <div className="flex items-center justify-end gap-x-4 max-sm:flex-wrap max-sm:gap-y-4">
          <Pagination
            className="shrink-0"
            current={currentPage}
            total={totalPages}
            pageSize={1}
            onChange={pageChagneEventHandler}
            showSizeChanger={false}
          />
          <JobsViewCountSelector />
          <NoDelayButton />
          <TextField
            id={`search-job`}
            SuffixEl={<Icon.Search1 />}
            type="search"
            placeholder="검색하기"
            onChange={onChangeSearchHandler}
            className="bg-white px-2"
            wrapperClassName="max-sm:w-fit"
          />
          <Button
            onClick={allSelectButtonHandler}
            className={`flex h-[43px] items-center gap-x-1 bg-white ${
              isSelectedDeleteJob && 'ring-2'
            }`}
          >
            {isSelectedDeleteJob ? '전체 해제' : '전체 선택'}&nbsp;
            {isSelectedDeleteJob ? `(${deleteSelectedJobs.length})` : null}
          </Button>
          {/* //! s3 cors bug로 주석처리 */}
          <Button
            onClick={outputsDowload}
            className="flex h-[43px] items-center gap-x-1 bg-blue-500 text-16 font-bold text-white"
            disabled={deleteSelectedJobs.length === 0}
          >
            <Icon.Download1 className=" group-hover:text-white group-disabled:text-gray-400" />
            다운로드
          </Button>
          <Button
            onClick={deleteJobsButtonHandler}
            className="flex h-[43px] items-center gap-x-1 bg-red-500 text-16 font-bold text-white"
            disabled={deleteSelectedJobs.length === 0}
          >
            삭제
          </Button>
        </div>
      </div>
      {/* body */}
      <div className="w-full px-10 max-sm:px-6">
        {/* 생성용 jobRow */}
        <div className="mb-10 w-full">
          <p className="mb-4 text-24 font-semibold">Create Job</p>
          <CreateJobRow />
        </div>
        <div>
          <div className="mb-4 grid w-full grid-cols-auto-1fr items-center gap-x-4">
            <div className="w-fit grow text-24 font-semibold">
              {paramStatus} History {resultJobs.length}건
            </div>
            <div className="w-full border-t border-black"></div>
          </div>
          <div className="w-full space-y-10">
            {jobsPollingStatus !== 'success' && (
              <div className="pt-10 center-box">
                <LoadingSpinnerAntd size={50} />
              </div>
            )}
            {jobsPollingStatus === 'success' && resultJobs?.length === 0 && (
              <div className="pt-10 text-24 font-bold center-box">
                검색 결과가 없습니다.
              </div>
            )}
            {/* view Jobs  */}
            {JobRows}
          </div>
        </div>
      </div>
      <ScrollToTopButton />
      <DismissModal
        onClick={excuteDeleteJobs}
        title="삭제하시겠습니까?"
        openId={deleteJobsModalId}
        buttonTitle="삭제"
      >
        <div className="p-2">{deleteSelectedJobs.length}개의 항목</div>
      </DismissModal>
    </div>
  );
});
export { JobContainer };
