import { HTMLAttributes, useEffect } from 'react';

import { createPortal } from 'react-dom';

import { LoadingSpinnerAntd } from './LoadingSpinnerAntd';

interface Props extends HTMLAttributes<HTMLDivElement> {
  open: boolean;
  title?: string;
}

const LoadingModal = ({ open, title }: Props) => {
  useEffect(() => {
    if (open) {
      document.documentElement.style.overflow = 'hidden';
      // 포커스가 잡혀있는 요소 blur 처리
      (document.activeElement as HTMLElement)?.blur();
    } else {
      document.documentElement.style.overflow = 'auto';
    }
    return () => {
      document.documentElement.style.overflow = 'auto';
    };
  }, [open]);

  return (
    <>
      {open &&
        // headressUI modal과 동일하게 portal로 렌더해야 탑레이어에 위치하여 오작동을 방지함
        createPortal(
          <div className="center-box fixed inset-0 z-[9999] bg-gray-50 bg-opacity-75">
            <div className="flex flex-col justify-center gap-y-4">
              <LoadingSpinnerAntd size={50} />
              <p className="text-20 font-bold">{title ?? 'loading...'}</p>
            </div>
          </div>,
          document.body,
        )}
    </>
  );
};

export { LoadingModal };
