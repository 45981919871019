import { FC } from 'react';

import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

type Props = {
  size: number;
};

const LoadingSpinnerAntd: FC<Props> = ({ size = 32 }) => {
  return (
    <Spin
      indicator={<LoadingOutlined style={{ fontSize: size, color: 'black' }} spin />}
    />
  );
};
export { LoadingSpinnerAntd };
