import { atom, atomFamily } from 'recoil';

import { WebsocketStatus } from '@api/mongo/interfaces/status.interface';
import { Socket } from 'socket.io-client';
import dayjs from 'dayjs';
import { QueryStatus } from 'react-query';

export type JobStatusBase = {
  Todo: string;
  Doing: string;
  Done: string;
  Canceled: string;
  Rejected: string;
  Failed: string;
  Error: string;
};

export type JobStatusToNumber = {
  [K in keyof JobStatusBase]: number;
};

// stats
export const jobsStatsSocketDataAtomFamily = atomFamily<
  JobStatusToNumber,
  {
    userId?: string;
  }
>({
  key: 'jobsStatsSocketDataAtomFamily',
  default: undefined,
});
export const jobsStatsSocketStatusAtomFamily = atomFamily<
  WebsocketStatus,
  {
    userId?: string;
  }
>({
  key: 'jobsStatsSocketStatusAtomFamily',
  default: WebsocketStatus.Disconnect,
});

export const jobsStatsSocketAtomFamily = atomFamily<
  Socket | null,
  {
    userId?: string;
  }
>({
  key: 'jobsStatsSocketAtomFamily',
  default: null,
});

//stats polling data
export const jobsStatsPollingDataAtomFamily = atomFamily<
  JobStatusToNumber,
  {
    userId?: string;
  }
>({
  key: 'jobsStatsPollingDataAtomFamily',
  default: undefined,
});
//stats polling status
export const jobsStatsPollingStatusAtomFamily = atomFamily<
  QueryStatus,
  {
    userId?: string;
  }
>({
  key: 'jobsStatsPollingStatusAtomFamily',
  default: 'idle',
});

export const jobsStatsSelectedDate = atom<dayjs.Dayjs>({
  key: 'jobsStatsSelectedDate',
  default: dayjs().startOf('day'),
});
