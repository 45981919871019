import React, { FC, HTMLAttributes, PropsWithChildren } from 'react';

type Props = PropsWithChildren<HTMLAttributes<HTMLDivElement>>;

const Body: FC<Props> = React.memo(({ children, className, ...props }) => {
  return (
    <div className={`max-h-full min-h-screen w-full ${className}`} {...props}>
      {children}
    </div>
  );
});
export { Body };
