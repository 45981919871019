import { useRecoilState } from 'recoil';

import { jobDelayAtom } from '../state/JobContainer.atom';

// job 생성 시 delay 유무 선택 컴포넌트
const NoDelayButton = () => {
  const [isJobDelay, setIsJobDelay] = useRecoilState(jobDelayAtom);

  return (
    <div>
      <input
        onChange={() => {
          setIsJobDelay((prev) => !prev);
        }}
        id="noDelay"
        className="p-2"
        type="checkbox"
        checked={isJobDelay}
      />
      <label htmlFor="noDelay" className="cursor-pointer">
        No delay
      </label>
    </div>
  );
};
export { NoDelayButton };
