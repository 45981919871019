import { selectorFamily } from 'recoil';

import { QueryDataState, WebsocketState } from 'src/interfaces';

import {
  JobStatusToNumber,
  jobsStatsPollingDataAtomFamily,
  jobsStatsPollingStatusAtomFamily,
  jobsStatsSocketAtomFamily,
  jobsStatsSocketDataAtomFamily,
  jobsStatsSocketStatusAtomFamily,
} from './StatsContainer.atom';

export const jobsStatsSocketSelectorFamily = selectorFamily<
  WebsocketState<JobStatusToNumber>,
  {
    userId?: string;
  }
>({
  key: 'jobsStatsSocketSelectorFamily',
  get:
    ({ userId }) =>
    ({ get }) => ({
      status: get(jobsStatsSocketStatusAtomFamily({ userId })),
      data: get(jobsStatsSocketDataAtomFamily({ userId })),
      socket: get(jobsStatsSocketAtomFamily({ userId })),
    }),
});

export const jobsStatsPollingSelectorFamily = selectorFamily<
  QueryDataState<JobStatusToNumber>,
  {
    userId?: string;
  }
>({
  key: 'jobsStatsSocketSelectorFamily',
  get:
    ({ userId }) =>
    ({ get }) => ({
      status: get(jobsStatsPollingStatusAtomFamily({ userId })),
      data: get(jobsStatsPollingDataAtomFamily({ userId })),
    }),
});
