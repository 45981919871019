import { useEffect } from 'react';

import { RecoilState, useRecoilState } from 'recoil';

import _ from 'lodash';
import { WebsocketStatus } from '@api/mongo/interfaces/status.interface';

import { useWebSocket } from './useWebSocket';

export const useRecoilWebSocketByFilter = <T>(
  statusAtom: RecoilState<WebsocketStatus>,
  dataAtom: RecoilState<T>,
  webSocketUrl: string,
  { filter, populate, options, select }: any,
  enabled?: boolean,
) => {
  const [status, setStatus] = useRecoilState(statusAtom);
  const [data, setData] = useRecoilState(dataAtom);
  const { socket } = useWebSocket(webSocketUrl); // 기존 socket state의 대체
  const isConnected = status === WebsocketStatus.Connect;

  useEffect(() => {
    if (!enabled) return;
    console.log(`useRecoilWebsocket: webSocketUrl=${webSocketUrl}, filter=${filter}`);

    socket.on('connect', async () => {
      // setStatus(WebsocketStatus.Connect); // 여기서하면 안되고 setData가 된 다음에 해야함
      socket.emit('subscribeByFilter', {
        filter,
        populate,
        options,
        select,
      });
    });

    socket.on('disconnect', async (data) => {
      setStatus(WebsocketStatus.Disconnect);
    });

    socket.on('watchByFilter', async (data) => {
      // console.log(
      //   `WS ${webSocketUrl}?watchByFilter: ${JSON.stringify({ id: socket.id, data })}`,
      // );
      setData(data);
    });

    socket.on('error', async (data) => {
      throw new Error(
        `WS ${webSocketUrl}?error: ${JSON.stringify({ id: socket.id, data })}`,
      );
    });

    return () => {
      setStatus(WebsocketStatus.Disconnect);
      socket.off('connect');
      socket.off('disconnect');
      socket.off('watchByFilter');
      socket.off('error');
    };
  }, [
    socket,
    enabled,
    filter,
    options,
    populate,
    select,
    setData,
    setStatus,
    webSocketUrl,
  ]); // filter, populate, options, select 넣으면 알수없는 이유로 무한루프 //@todo

  useEffect(() => {
    if (!_.isNil(data)) {
      setStatus(WebsocketStatus.Connect);
    }
  }, [data, setStatus]);

  // args 변경 시 소켓연결 갱신용
  useEffect(() => {
    socket.disconnect();
    socket.connect();
  }, [filter, populate, options, select, socket]);

  return {
    isConnected,
    status,
    data,
    socket,
  };
};
