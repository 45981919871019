import React, { FC, useMemo } from 'react';

import { TextField } from '@components/TextField';
import { InputImages, Job } from '@api/mongo/interfaces/job.interface';
import { fileBucketUrl } from '@plugin/s3Image';
import { MultipleImageInput } from '@containers/BatchJobContainer/components/MultipleImageInput';

import { capitalizeFirstLetter } from '../utils/capitalizeFirstLetter';

export type InputCardListProps = {
  job?: Job;
};

export const INPUT_FIELD: InputImages[] = ['source', 'target', 'optical', 'card'];

const InputCardList: FC<InputCardListProps> = React.memo(({ job }) => {
  const InputCards = useMemo(() => {
    return INPUT_FIELD.map((item) => {
      const url = fileBucketUrl(job?.input[item]?.key ?? '');
      return (
        <div className="rounded-md border bg-white p-2" key={item}>
          <MultipleImageInput
            viewOnly
            labelNode={<p>{capitalizeFirstLetter(item)}</p>}
            defaultUrls={[url]}
            previewBoxClassName="h-full"
          />
        </div>
      );
    });
  }, [job?.input]);

  return (
    <div className="relative col-span-5 grid grid-cols-5 gap-x-2 rounded-md bg-gray-100 p-2">
      <div className="absolute -top-7 left-2 z-10 text-18 font-medium text-gray-400">
        Input
      </div>
      {/* {InputCards} */}
      {InputCards}
      <div className="space-y-4 rounded-md border bg-white p-2">
        <p className="py-1">Text Data</p>
        <TextField
          id={`inputName-${job?._id}`}
          tabIndex={2}
          className="bg-gray-100 px-4"
          disabled
          defaultValue={job?.input.name}
        />
        <TextField
          id={`opticalName-${job?._id}`}
          disabled
          tabIndex={2}
          className="bg-gray-100 px-4"
          defaultValue={job?.input.optical_name}
        />
      </div>
    </div>
  );
});
export { InputCardList };
