import { useRecoilValue } from 'recoil';

import { useAuth } from '@hooks/useAuth';
import { fetcher, queryString, useRecoilQuery } from '@hooks';

import { jobsStatsPollingSelectorFamily } from './StatsContainer.selector';
import {
  jobsStatsPollingDataAtomFamily,
  jobsStatsPollingStatusAtomFamily,
  jobsStatsSelectedDate,
} from './StatsContainer.atom';

export const useJobsStatsPolling = ({ userId }: { userId: string }) => {
  const { isAuthenticated } = useAuth();
  const fromDate = useRecoilValue(jobsStatsSelectedDate);

  const jobsUrl = `/general/job/stats?fromDate=${fromDate}`;

  useRecoilQuery(
    jobsStatsPollingStatusAtomFamily({ userId }),
    jobsStatsPollingDataAtomFamily({ userId }),
    jobsUrl,
    fetcher,
    {
      refetchInterval: 1000,
      // enabled: !!isAuthenticated,
    },
  );
};
