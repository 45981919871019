import { useMemo } from 'react';

import { useRecoilValue } from 'recoil';

import { useAuth } from '@hooks/useAuth';
import { useRecoilWebSocketByFilter } from '@hooks/useRecoilWebsocketByFilter';
import {
  socketToyDataAtomFamily,
  socketToySelectorFamily,
  socketToyStatusAtomFamily,
} from '@state/atom/webSocket.toy.atom';
import { WebsocketStatus } from '@api/mongo/interfaces/status.interface';
import { useUser } from '@hooks/useUser';

const ToyContainer = () => {
  const { loginByUsernamePassword, token } = useAuth();
  const { user } = useUser();

  const login = async (user: 0 | 1) => {
    await loginByUsernamePassword({
      username: `${user}`,
      password: `${user}`,
    });
  };
  const args = useMemo(
    () => ({
      // filter: { title: { $regex: 'msg' } },
      filter: {},
      populate: { path: 'comments' },
    }),
    [],
  );

  useRecoilWebSocketByFilter(
    socketToyStatusAtomFamily(''),
    socketToyDataAtomFamily(''),
    '/customer/blog',
    args,
  );

  const { data, status } = useRecoilValue(socketToySelectorFamily(''));
  return (
    <div className="m-4 space-y-8">
      <button onClick={() => login(0)} className="border bg-slate-400 p-4">
        로그인 유저 0
      </button>
      <button onClick={() => login(1)} className="ml-4 border bg-slate-400 p-4">
        로그인 유저 1
      </button>
      <div className="text-20">
        {user && <p>user :{JSON.stringify(user)}</p>}
        {token && !user && <p>서버연결 확인 필요</p>}
      </div>
      <div className="overflow-auto text-20 font-semibold">
        {token ? <p>token : {token}</p> : <p>로그인 필요</p>}
      </div>
      {status === WebsocketStatus.Connect && data && (
        // <div>{JSON.stringify(data)}</div>
        <div className="space-y-4">
          {data.map((data: any) => (
            <div className="bg-teal-100 p-4 font-medium">{JSON.stringify(data)}</div>
          ))}
        </div>
      )}
    </div>
  );
};
export { ToyContainer };
