import { Body } from '@components/Body';
import { HeaderContainer } from '@containers/HeaderContainer';
import { StatsContainer } from '@containers/StatsContainer';

const StatsPage = () => {
  return (
    <>
      <HeaderContainer />
      <Body>
        <StatsContainer />
      </Body>
    </>
  );
};

export { StatsPage };
