import { Image } from '@api/mongo/interfaces';

// (card01.png, source02.png) => 'card01-source02'
export const joinedFilename = (files: (Image | undefined)[]) => {
  return files
    .map((file) => {
      if (file) {
        return file.name.split('.').slice(0, -1).join('.');
      }
      return '';
    })
    .join('-');
};
