import { CreateBatchJobSection } from './components/CreateBatchJobSection';

const BatchJobContainer = () => {
  return (
    <>
      <CreateBatchJobSection />
    </>
  );
};
export { BatchJobContainer };
