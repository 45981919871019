import { useSetRecoilState } from 'recoil';
import { atom } from 'recoil';

export const appLoadingAtom = atom({
  key: 'appLoadingAtom',
  default: false,
});

export const useAppLoading = () => {
  const setAppLoading = useSetRecoilState(appLoadingAtom);

  const loadingTryCatch = async (fn: any) => {
    try {
      setAppLoading(true);
      await fn();
      // eslint-disable-next-line no-useless-catch
    } catch (error) {
      throw error;
    } finally {
      setAppLoading(false);
    }
  };

  return { loadingTryCatch };
};
