import React, { FC, useCallback, useId } from 'react';

import { useRecoilValue, useSetRecoilState } from 'recoil';

import dayjs from 'dayjs';
import { JobStatus, StatusBase } from '@api/mongo/interfaces/status.interface';
import { notificationInstanceAtom } from '@state/atom/notification.atom';
import { notificateSuccess } from 'src/@utils/noti.utils';
import { Tooltip } from 'antd';
import { Icon } from '@components/Icon';
import { fileBucketUrl } from '@plugin/s3Image';

import { JobRowProps } from './JobRow';
import { jobCreateInputAtom } from '../state/JobContainer.atom';
import { downloadOutput } from '../utils/downloadOutputUtils';
import { INPUT_FIELD } from './InputCardList';
import { capitalizeFirstLetter } from '../utils/capitalizeFirstLetter';
import { OUTPUT_FIELD } from './OutputCardList';
const JobRowMobile: FC<JobRowProps> = ({ job, selected, className, ...props }) => {
  const noti = useRecoilValue(notificationInstanceAtom);
  const uuid = useId();
  const checkBoxid = `checkbox-${uuid}`;
  const jobCreatedAt = dayjs(job?.createdAt).format('YYYY-MM-DD HH:mm:ss');
  const setJobCreateInput = useSetRecoilState(jobCreateInputAtom);
  const outputDonwloadButtonHandler = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.stopPropagation();
      if (job.status === JobStatus.Done) {
        downloadOutput([job]);
      } else {
        alert('완료된 작업만 다운로드할 수 있습니다.');
      }
    },
    [job],
  );
  const reRunButtonHandler = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.stopPropagation();
      setJobCreateInput(() => {
        const dto = {
          inputName: job.input.name,
          jobName: job.name,
          optical_name: job.input.optical_name,
          source: job.input.source,
          target: job.input.target,
          optical: job.input.optical,
          card: job.input.card,
        };
        return dto;
      });
      notificateSuccess(noti, 'New Section에 등록되었습니다');
      return;
    },
    [job, noti, setJobCreateInput],
  );
  const JobStatusText = useCallback(() => {
    const setColor = () => {
      switch (job.status) {
        case JobStatus.Done: {
          return 'text-green-500';
        }
        case JobStatus.Error: {
          return 'text-red-500';
        }
        case JobStatus.Canceled: {
          return 'text-yellow-500';
        }
        default: {
          return 'text-gray-500';
        }
      }
    };
    return <p className={setColor()}>{job.status}</p>;
  }, [job.status]);

  const DownloadButton = useCallback(() => {
    return (
      <Tooltip title={`output 다운로드`}>
        <button
          type="button"
          onClick={outputDonwloadButtonHandler}
          className="group h-full w-fit rounded-sm border-2 border-gray-200 transition-all center-box hover:border-blue-500 hover:bg-blue-500"
        >
          <Icon.Download1 className=" text-gray-400 group-hover:text-white" />
        </button>
      </Tooltip>
    );
  }, [outputDonwloadButtonHandler]);

  const ImageBox = useCallback(({ url }: { url: string }) => {
    return (
      <div className="aspect-1 overflow-hidden rounded-sm border">
        <img
          src={url}
          width={100}
          height={100}
          className="h-full w-full object-scale-down"
        />
      </div>
    );
  }, []);

  return (
    <div
      className={`rounded-lg bg-white p-4 shadow-md ${className} ${
        selected ? 'ring-2 hover:ring-2' : ''
      }`}
      {...props}
    >
      <div className="flex justify-between">
        <p className="">{job?.name ?? ''}</p>
        <JobStatusText />
      </div>
      <div className="flex items-center justify-between">
        <p className="text-gray-500">{jobCreatedAt}</p>
        <input
          id={checkBoxid}
          className="h-6 w-6 cursor-pointer"
          type="checkbox"
          onChange={() => {
            return;
          }}
          checked={selected}
        />
      </div>

      <div className="flex justify-between">
        <p>id:{job._id}</p>
      </div>

      <p>input</p>
      <div className="grid grid-cols-4 gap-x-1">
        {INPUT_FIELD.map((item, index) => {
          const url = fileBucketUrl(job?.input[item]?.key ?? '');
          return <ImageBox url={url} key={index} />;
        })}
      </div>

      <div className="mt-1 grid grid-cols-4 grid-rows-auto-1fr gap-x-1">
        {OUTPUT_FIELD.map((item, index) => {
          const label = capitalizeFirstLetter(item);
          const url = fileBucketUrl(job?.output?.[item]?.key ?? '');
          let status = job?.tasks[index]?.status;
          if (index === 3) {
            status = job?.tasks[2]?.status;
          }
          return (
            <React.Fragment key={index}>
              <p className="row-start-1 text-14">{label}</p>
              {status === StatusBase.Done ? (
                <ImageBox url={url} />
              ) : (
                <div className="aspect-1 border center-box">{status}</div>
              )}
            </React.Fragment>
          );
        })}
      </div>

      {/* text data */}
      <div className="mt-2 grid grid-cols-2 gap-x-2">
        <p className="rounded-full bg-gray-200 px-3 py-1">{job.input.name}</p>
        <p className="rounded-full bg-gray-200 px-3 py-1">{job.input.optical_name}</p>
      </div>

      <div className="mt-2 flex h-fit w-full items-center gap-x-2">
        <DownloadButton />
        <button
          className="w-full rounded-sm bg-blue-500 p-1 text-white"
          onClick={reRunButtonHandler}
        >
          RE RUN
        </button>
      </div>
    </div>
  );
};
export { JobRowMobile };
