import { useRecoilValue, useSetRecoilState } from 'recoil';

import { Images, InputItemType, createImagesApi } from '@api/createImage';
import { nestMongoApi } from '@api/mongo/controllers';
import { Job, JobCreateDto } from '@api/mongo/interfaces/job.interface';
import { useAppLoading } from '@hooks/useAppLoading';
import { Image } from '@api/mongo/interfaces';
import { isNil } from 'lodash';

import { jobCreateInputAtom, jobDelayAtom } from '../state/JobContainer.atom';

const useCreateRunJob = () => {
  const { create, findById } = nestMongoApi<Job, JobCreateDto>('/general/job');
  const { loadingTryCatch } = useAppLoading();
  const isNoDelay = useRecoilValue(jobDelayAtom);
  const setJobCreateInput = useSetRecoilState(jobCreateInputAtom);

  const setJobInputAtom = (job: Job) => {
    setJobCreateInput(() => {
      const dto = {
        inputName: job.input.name,
        jobName: job.name,
        optical_name: job.input.optical_name,
        source: job.input.source,
        target: job.input.target,
        optical: job.input.optical,
        card: job.input.card,
      };
      return dto;
    });
  };

  const isImageType = (item: any): item is Image => {
    return item && '_id' in item;
  };

  const createJobDto = (
    images: Images,
    jobName: string,
    inputName: string,
    optical_name: string,
  ) => {
    return {
      name: jobName,
      input: {
        source: isImageType(images[0]) ? images[0]._id : '',
        target: isImageType(images[1]) ? images[1]._id : '',
        optical: isImageType(images[2]) ? images[2]._id : '',
        card: isImageType(images[3]) ? images[3]._id : '',
        name: inputName,
        optical_name: optical_name,
      },
    };
  };

  const createRunJob = async (
    imageFiles: InputItemType[],
    jobName: string,
    inputName: string,
    optical_name: string,
  ) => {
    let createJob: Job | null = null;
    let images: any;

    //이미지 업로드동안 로딩
    await loadingTryCatch(async () => {
      try {
        images = await createImagesApi(imageFiles);
      } catch (err) {
        console.log(`createImagesApi Error :`, err);
      }
    });

    const executeJobCreation = async () => {
      //이미지 업로드 후 오브젝트 재사용하여 과도한 이미지 업로드 방지
      setJobCreateInput(() => {
        const update = {
          inputName: inputName,
          jobName: jobName,
          optical_name: optical_name,
          source: images[0],
          target: images[1],
          optical: images[2],
          card: images[3],
        };
        return update;
      });
      const jobDto = createJobDto(images, jobName, inputName, optical_name);
      const { data: createJobResult } = await create(jobDto);
      createJob = createJobResult;
    };
    try {
      if (isNoDelay) {
        await executeJobCreation();
      } else {
        await loadingTryCatch(executeJobCreation);
      }
      if (!isNil(createJob)) {
        await findById(`${(createJob as Job)._id}/run`);
      }
    } catch (err) {
      console.error(`createRunJob Error : ${err}`);
    }
  };

  return {
    createRunJob,
    setJobInputAtom,
  };
};

export { useCreateRunJob };
