import { FC } from 'react';

import { useRecoilValue } from 'recoil';

import { Route, Routes, ScrollRestoration } from 'react-router-dom';
import { JobPage } from '@pages/JobPage';
import { BatchJobPage, LoginPage, StatsPage } from '@pages';
import { ToyPage } from '@pages/ToyPage';
import { LoadingModal } from '@components/LoadingModal';
import { appLoadingAtom } from '@hooks/useAppLoading';

const RootRouter: FC = () => {
  const appLoading = useRecoilValue(appLoadingAtom);

  return (
    <>
      <LoadingModal open={appLoading} />
      <ScrollRestoration
        getKey={(location, matches) => {
          // paths에 추가한 pathname은 key로 스크롤 관리
          const paths: string[] = [];
          return paths.includes(location.pathname) ? location.key : location.pathname;
        }}
      />
      <Routes>
        <Route path="/" element={<LoginPage />} />
        <Route path="/job" element={<JobPage />} />
        <Route path="/stats" element={<StatsPage />} />
        <Route path="/batch-job" element={<BatchJobPage />} />
        <Route path="/toy" element={<ToyPage />} />
      </Routes>
    </>
  );
};

export { RootRouter };
