import React, { FC } from 'react';

import { Job, Output } from '@api/mongo/interfaces/job.interface';

import { capitalizeFirstLetter } from '../utils/capitalizeFirstLetter';
import { OutputCard } from './OutputCard';

type OutputCardListProps = { job: Job };

export const OUTPUT_FIELD: (keyof Output)[] = ['swap', 'enhance', 'optical', 'card'];

const OutputCardList: FC<OutputCardListProps> = React.memo(({ job }) => {
  return (
    <div className="relative col-span-4 grid grid-cols-4 gap-x-2 rounded-md bg-gray-300 p-2">
      <div className="absolute -top-7 left-2 z-10 text-18 font-medium text-gray-400">
        Output
      </div>

      {OUTPUT_FIELD.map((item, index) => {
        const label = capitalizeFirstLetter(item);
        const output = job?.output?.[item];
        let status = job?.tasks[index]?.status;
        if (index === 3) {
          status = job?.tasks[2]?.status;
        }
        return (
          <OutputCard key={item} label={label} output={output} taskStatus={status} />
        );
      })}
    </div>
  );
});
export { OutputCardList };
