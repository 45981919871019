import { selectorFamily } from 'recoil';

import { Job } from '@api/mongo/interfaces/job.interface';
import { QueryDataState, WebsocketState } from 'src/interfaces';

import {
  jobsSocketStatusAtomFamily,
  jobsSocketDataAtomFamily,
  jobsSocketAtomFamily,
  // jobsStatusAtomFamily,
  // jobsDataAtomFamily,
  jobsPollingDataAtomFamily,
  jobsPollingStatusAtomFamily,
} from './JobContainer.atom';

export const jobsSocketSelectorFamily = selectorFamily<
  WebsocketState<Job[] | null>,
  {
    userId?: string;
  }
>({
  key: 'jobsSocketSelectorFamily',
  get:
    ({ userId }) =>
    ({ get }) => ({
      status: get(jobsSocketStatusAtomFamily({ userId })),
      data: get(jobsSocketDataAtomFamily({ userId })),
      socket: get(jobsSocketAtomFamily({ userId })),
    }),
});

export const jobsPollingSelectorFamily = selectorFamily<
  QueryDataState<Job[]>,
  { userId: string }
>({
  key: 'jobsSelectorFamily',
  get:
    ({ userId }) =>
    ({ get }) => ({
      status: get(jobsPollingStatusAtomFamily({ userId })),
      data: get(jobsPollingDataAtomFamily({ userId })),
    }),
});

// export const jobsSelectorFamily = selectorFamily<
//   QueryDataState<Job[] | null>,
//   { userId: string }
// >({
//   key: 'jobsSelectorFamily',
//   get:
//     ({ userId }) =>
//     ({ get }) => ({
//       status: get(jobsStatusAtomFamily(userId)),
//       data: get(jobsDataAtomFamily(userId)),
//     }),
// });
