import {
  ForwardRefRenderFunction,
  Fragment,
  HTMLAttributes,
  forwardRef,
  useImperativeHandle,
  useRef,
} from 'react';
import React from 'react';

import { useRecoilState } from 'recoil';

import { Dialog, Transition } from '@headlessui/react';
import { modalOpenAtomFamily } from '@state/atom/openAtom';
import { useModalHook } from 'src/@hooks/useModalHooks';
import { RemoveScroll } from 'react-remove-scroll';
import { Icon } from '@components/Icon';

import { CustomCallback } from './interface';

export interface Props extends HTMLAttributes<HTMLDivElement> {
  openId: string;
  onClose?: CustomCallback<any, void>;
  className?: string;
  frameClassName?: string;
  position?: Position;
  closeButton?: boolean;
  onAfterLeave?: CustomCallback<any, void>;
}
/**
 * modal
 * @param openId
 * @param onClose: (option) 종료시 callback 함수
 * @param className: (option) 해당 모달의 className
 * @param frameClassName: (option) 해당 모달의 위치 조정 가능
 * @param position: (option) start 위 center 중간 end 아래
 * @param closeButton: (option) X버튼 사용 여부
 * @param onAfterLeave: (option) 모달이 완전히 닫히고 난 후 호출되는 콜백함수
 */
const Modal: ModalProps = (
  {
    openId,
    children,
    onClose,
    className,
    frameClassName,
    position = 'center',
    closeButton = false,
    onAfterLeave,
  },
  ref,
) => {
  useModalHook(openId);
  const [open, setOpen] = useRecoilState(modalOpenAtomFamily(openId));
  const clickInProgressRef = useRef(false); // esc, Background Overlay 연타 시 뒤로가는 버그 대응을 위한 Ref
  const confirmRef = useRef(false);

  //모달을 닫을 때, 상위요소에서 ref를 전달하고, 아래 함수를 사용할 것
  useImperativeHandle(ref, () => ({
    onCloseModal: () => onCloseModal(false),
    onCloseAsyncModal: (callback) => onCloseAsyncModal(callback),
    confirmRef: confirmRef,
  }));

  //단순히 모달을 닫을 때 호출함, *props.onClose 호출됨
  const onCloseModal = (event: boolean | React.MouseEvent<SVGSVGElement>) => {
    if (!clickInProgressRef.current) return;
    onClose && onClose(event);
    closeModal();
  };

  //모달 닫힐 때 호출되는 async 콜백함수 *props.onClose 호출안됨
  const onCloseAsyncModal = async (callback: () => Promise<void> | void) => {
    if (!clickInProgressRef.current) return;
    await callback();
    closeModal();
  };

  //모달이 완전히 닫히고 난 후 콜백함수
  const onAfterLeaveHandler = () => {
    if (confirmRef.current) {
      onAfterLeave && onAfterLeave('');
      confirmRef.current = false;
    }
  };

  const closeModal = () => {
    setOpen(false);
    history.back();
    clickInProgressRef.current = false;
  };

  const onBeforeEnter = () => {
    clickInProgressRef.current = true;
  };

  return (
    <Transition
      appear
      show={open}
      as={Fragment}
      beforeEnter={onBeforeEnter}
      afterLeave={onAfterLeaveHandler}
    >
      <Dialog
        as="div"
        className={`fixed z-50 grid ${setPosition(position)}`}
        onClose={onCloseModal}
      >
        {/* Background Overlay */}
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-200"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className={`fixed inset-0 bg-black bg-opacity-70`} />
        </Transition.Child>
        {/* This element is to trick the browser into centering the modal contents. */}
        {/* <span className={`hidden h-screen`} aria-hidden="true"></span> */}
        <RemoveScroll>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-200"
            leave="ease-in duration-200"
            {...setTransition(position)}
          >
            <div className="fixed inset-0 overflow-auto">
              <div
                className={`${frameClassName} flex h-fit min-h-full justify-center ${setVerticalAlign(
                  position,
                )}`}
              >
                <Dialog.Panel
                  className={`${className} relative max-h-fit max-sm:w-full ${setRounded(
                    position,
                  )}`}
                >
                  {closeButton && (
                    <div className="absolute right-4 top-4">
                      <Icon.Close
                        className="h-5 w-5 cursor-pointer fill-black"
                        onClick={onCloseModal}
                      />
                    </div>
                  )}
                  {children}
                </Dialog.Panel>
              </div>
            </div>
          </Transition.Child>
        </RemoveScroll>
      </Dialog>
    </Transition>
  );
};
export default forwardRef(Modal);

type Position = 'start' | 'center' | 'end';

type ModalProps = ForwardRefRenderFunction<ModalRefHandle, Props>;

export type ModalRefHandle = {
  onCloseModal: () => void;
  onCloseAsyncModal: (callback: () => Promise<void> | void) => void;
  confirmRef: React.MutableRefObject<boolean>;
};

const setPosition = (position: Position) => {
  switch (position) {
    case 'start':
      return ' right-0 left-0 top-0';
    case 'center':
      return 'inset-0';
    case 'end':
      return 'bottom-0 right-0 left-0';
    default:
      'inset-0';
  }
};

const setVerticalAlign = (position: Position) => {
  switch (position) {
    case 'start':
      return 'items-start';
    case 'center':
      return 'items-center';
    case 'end':
      return 'items-end';
    default:
      'inset-0';
  }
};

const setRounded = (position: Position) => {
  switch (position) {
    case 'start':
      return 'rounded-b-3xl';
    case 'center':
      return 'rounded-3xl';
    case 'end':
      return 'rounded-t-3xl';
    default:
      'rounded-3xl';
  }
};

const setTransition = (position: Position) => {
  switch (position) {
    case 'start':
      return {
        enterFrom: 'opacity-0 -translate-y-8',
        enterTo: 'opacity-100 translate-y-0',
        leaveFrom: 'opacity-100 translate-y-0',
        leaveTo: 'opacity-0 -translate-y-8',
      };
    case 'center':
      return {
        enterFrom: 'opacity-0 scale-95',
        enterTo: 'opacity-100 scale-100',
        leaveFrom: 'opacity-100 scale-100',
        leaveTo: 'opacity-0 scale-95',
      };
    case 'end':
      return {
        enterFrom: 'opacity-0 translate-y-8',
        enterTo: 'opacity-100 translate-y-0',
        leaveFrom: 'opacity-100 translate-y-0',
        leaveTo: 'opacity-0 translate-y-8',
      };
  }
};
