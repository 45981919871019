import { UserRole } from '@api/mongo/interfaces/user.interface';
import { api_ } from '@plugin/axios';
import { AxiosResponse } from 'axios';
import { DeleteResult, UpdateResult } from 'mongodb';
import { FilterQuery, QueryOptions, PopulateOptions } from 'mongoose';

export type MongoApiParams<Entity> = {
  id: string;
  roles?: UserRole[];
  filter?: FilterQuery<Entity>;
  populate?: PopulateOptions;
  options?: QueryOptions;
  select?: string;
};

export type NestMongoApi = <Entity, Dto>(
  endpoint: string,
) => {
  create: (
    dto: Dto,
    params?: Pick<MongoApiParams<Entity>, 'roles'>,
  ) => Promise<AxiosResponse<Entity>>;

  findMany: (
    params: Omit<MongoApiParams<Entity>, 'id'>,
  ) => Promise<AxiosResponse<Entity[]>>;
  findById: (
    id: string,
    params?: Omit<MongoApiParams<Entity>, 'filter'>,
  ) => Promise<AxiosResponse<Entity>>;

  updateMany: (
    update: Partial<Entity>,
    params?: Omit<MongoApiParams<Entity>, 'id' | 'populate' | 'select'>,
  ) => Promise<AxiosResponse<UpdateResult>>;
  updateById: (
    id: string,
    update: Partial<Entity>,
    params?: Pick<MongoApiParams<Entity>, 'options' | 'roles'>,
  ) => Promise<AxiosResponse<Entity | null>>;

  deleteMany: (
    params: Pick<MongoApiParams<Entity>, 'filter' | 'options' | 'roles'>,
  ) => Promise<AxiosResponse<DeleteResult>>;
  deleteById: (
    id: string,
    params?: Pick<MongoApiParams<Entity>, 'options' | 'roles'>,
  ) => Promise<AxiosResponse<Entity | null>>;

  count: (
    params?: Pick<MongoApiParams<Entity>, 'filter' | 'options' | 'roles'>,
  ) => Promise<AxiosResponse<number>>;
};

const nestMongoApi: NestMongoApi = <Entity, Dto>(endpoint: string) => {
  const create = async (dto: Dto) => {
    return await api_.post<Entity>(endpoint, dto);
  };

  const findMany = async (params?: Omit<MongoApiParams<Entity>, 'id'>) => {
    return await api_.get<Entity[]>(endpoint, { params });
  };
  const findById = async (
    id: string,
    params?: Omit<MongoApiParams<Entity>, 'filter'>,
  ) => {
    return await api_.get<Entity>(`${endpoint}/${id}`, { params });
  };

  const updateMany = async (
    update: Partial<Entity>,
    params?: Omit<MongoApiParams<Entity>, 'id' | 'populate' | 'select'>,
  ) => {
    return await api_.patch<UpdateResult>(endpoint, update, { params });
  };
  const updateById = async (
    id: string,
    update: Partial<Entity>,
    params?: Pick<MongoApiParams<Entity>, 'options'>,
  ) => {
    return await api_.patch<Entity | null>(`${endpoint}/${id}`, update, {
      params,
    });
  };

  const deleteMany = async (
    params: Pick<MongoApiParams<Entity>, 'filter' | 'options'>,
  ) => {
    return await api_.delete<DeleteResult>(endpoint, { params });
  };
  const deleteById = async (
    id: string,
    params?: Pick<MongoApiParams<Entity>, 'options'>,
  ) => {
    return await api_.delete<Entity | null>(`${endpoint}/${id}`, {
      params,
    });
  };

  const count = async (params?: Pick<MongoApiParams<Entity>, 'filter' | 'options'>) => {
    return await api_.get<number>(`${endpoint}/count`, { params });
  };

  return {
    create,
    findMany,
    findById,
    updateMany,
    updateById,
    deleteMany,
    deleteById,
    count,
  };
};

export { nestMongoApi };
