import { atomFamily, selectorFamily } from 'recoil';

import { WebsocketStatus } from '@api/mongo/interfaces/status.interface';
import { Socket } from 'socket.io-client';

export const socketToyDataAtomFamily = atomFamily<any, string>({
  key: 'socketToyDataAtomFamily',
  default: null,
});

export const socketToyStatusAtomFamily = atomFamily<WebsocketStatus, string>({
  key: 'socketToyStatusAtomFamily',
  default: WebsocketStatus.Disconnect,
});

export const socketToyAtomFamily = atomFamily<Socket | null, string>({
  key: 'socketToyAtomFamily',
  default: null,
});

export const socketToySelectorFamily = selectorFamily<any, string>({
  key: 'socketToySelectorFamily',
  get:
    (param: string) =>
    ({ get }) => ({
      status: get(socketToyStatusAtomFamily(param)),
      data: get(socketToyDataAtomFamily(param)),
      socket: get(socketToyAtomFamily(param)),
    }),
});
