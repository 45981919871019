import { atom, atomFamily } from 'recoil';

import { Job, JobCreateInput } from '@api/mongo/interfaces/job.interface';
import { Socket } from 'socket.io-client';
import { WebsocketStatus } from '@api/mongo/interfaces/status.interface';
import { QueryStatus } from 'react-query';

// jobs
export const jobsSocketDataAtomFamily = atomFamily<
  Job[] | null,
  {
    userId?: string;
  }
>({
  key: 'jobSocketDataAtomFamily',
  default: null,
});
export const jobsSocketStatusAtomFamily = atomFamily<
  WebsocketStatus,
  {
    userId?: string;
  }
>({
  key: 'jobSocketStatusAtomFamily',
  default: WebsocketStatus.Disconnect,
});

export const jobsSocketAtomFamily = atomFamily<
  Socket | null,
  {
    userId?: string;
  }
>({
  key: 'jobSocketAtomFamily',
  default: null,
});

//job polling data
export const jobsPollingDataAtomFamily = atomFamily<
  Job[],
  {
    userId?: string;
  }
>({
  key: 'jobsPollingDataAtomFamily',
  default: undefined,
});
//job polling status
export const jobsPollingStatusAtomFamily = atomFamily<QueryStatus, { userId?: string }>({
  key: 'jobsPollingStatusAtomFamily',
  default: 'idle',
});

//seleted delete jobs atom
export const deleteSelectedJobsAtom = atom<Job[]>({
  key: 'deleteSelectedJobsAtom',
  default: [],
});

//run delay test
export const jobDelayAtom = atom({ key: 'jobDelayAtom', default: true });

export const defaultJobCreateInputValue = {
  source: null,
  target: null,
  optical: null,
  card: null,
  inputName: '',
  jobName: '',
  optical_name: '',
};

//createJob dto atom
export const jobCreateInputAtom = atom<JobCreateInput>({
  key: 'jobCreateInputAtom',
  default: defaultJobCreateInputValue,
});

//jobs view count
export const jobsViewCountAtom = atom<number>({
  key: 'jobViewCountAtom',
  default: 10,
});

//jobs page value
export const jobsCurrentPageAtom = atom<number>({
  key: 'jobsCurrentPageAtom',
  default: 1,
});
