import { FC } from 'react';

import { CustomCallback } from '@components/Modal/interface';

interface ConfirmButtonSetProps {
  confirmOnClick: CustomCallback<any, any>;
  secondaryOnClick: CustomCallback<any, any>;
  confirmText?: string;
  confirmClassName?: string;
  confirmButtonType?: 'button' | 'submit' | 'reset' | undefined;
  secondaryText?: string;
  secondaryClassName?: string;
  wrapperClassName?: string;
  showSecondaryButton?: boolean;
  ratio?: [string, string];
  fontSize?: string;
}
/**
 * 확인, 취소 버튼 컴포넌트셋 ex) 컨펌버튼이 필요한 모달에 사용
 * @param confirmOnClick: 컨펌버튼 콜백함수
 * @param secondaryOnClick: 세컨더리버튼 콜백함수
 * @param confirmText:(option) 확인버튼 문구 deafult = '확인'
 * @param confirmClassName: (option) 컨펌버튼 스타일
 * @param confirmButtonType: (option) 컨펌버튼 타입 지정 default ='button'
 * @param secondaryText: (option) 세컨더리버튼 텍스트 default = '취소'
 * @param secondaryClassName: (option) 세컨더리버튼 스타일
 * @param showSecondaryButton: (option) secondary button 렌더링 여부
 * @param ratio: (option) 취소,확인버튼의 비율 설정 default = ['50%','50%']
 * @param fontSize: (option) 버튼 텍스트 사이즈
 */
const ConfirmButtonSet: FC<ConfirmButtonSetProps> = ({
  confirmText = '확인',
  confirmOnClick,
  confirmClassName,
  confirmButtonType = 'button',
  secondaryText = '취소',
  secondaryOnClick,
  secondaryClassName,
  wrapperClassName,
  showSecondaryButton = false,
  ratio = ['50%', '50%'],
  fontSize,
}) => {
  const defaultButtonStyle = () => `py-3 font-semibold ${fontSize ?? 'text-16'}`;

  return (
    <div className={`flex gap-x-[14px] ${wrapperClassName}`}>
      {showSecondaryButton && (
        <button
          type="button"
          style={{ width: `${ratio[0]}` }}
          className={`button-secondary-nouvo ${defaultButtonStyle()} ${secondaryClassName}`}
          onClick={secondaryOnClick}
        >
          {secondaryText}
        </button>
      )}
      <button
        type={confirmButtonType}
        style={{ width: showSecondaryButton ? `${ratio[1]}` : `100%` }}
        className={`button-primary-nouvo ${defaultButtonStyle()} ${confirmClassName}`}
        onClick={confirmOnClick}
      >
        {confirmText}
      </button>
    </div>
  );
};

export { ConfirmButtonSet };
