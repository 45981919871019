import { FC, ReactNode, useRef } from 'react';

import Modal, { ModalRefHandle, Props } from './Modal';
import { CustomCallback } from './interface';
import { ConfirmButtonSet } from './ConfirmButtonSet';

interface ConfirmModalProps extends Props {
  title?: string;
  secondaryButtonText?: string;
  confirmButtonText?: string;
  onClick: CustomCallback<any, void>;
  onSecondaryClick?: CustomCallback<any, void>;
  showSecondaryButton?: boolean;
  ratio?: [string, string];
  children?: ReactNode;
}

/**
 *
 * @param showSecondaryButton - secondary button 나타냄
 * @param title - 제목
 * @param confirmButtonText - confirm button 텍스트
 * @param onClick - confirmButton onClick시 콜백함수
 * @param onSecondaryClick - secondaryButton onClick 콜백함수
 * @param secondaryButtonText - secondaryButton 텍스트
 * @param ratio - 취소,확인버튼 비율 설정 default = ['50%','50%']
 * @param children - ReactNode
 */
const ConfirmModal: FC<ConfirmModalProps> = ({
  title,
  secondaryButtonText,
  onClick,
  onSecondaryClick,
  showSecondaryButton,
  children,
  confirmButtonText,
  ratio,
  ...props
}) => {
  const { openId } = props;
  const modalRef = useRef<ModalRefHandle>(null);

  const onCloseModal = () => {
    modalRef.current?.onCloseModal();
  };

  const onConfirmButtonHandler = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    onClick(event);
    onCloseModal();
  };

  const onSecondaryButtonHandler = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    if (onSecondaryClick) {
      onSecondaryClick(event);
    }
    onCloseModal();
  };

  return (
    <Modal
      {...props}
      openId={openId}
      className="mx-6 bg-white"
      position="center"
      ref={modalRef}
    >
      <div className="space-y-4 p-5">
        {title && (
          <p className="rounded text-18 font-semibold text-gray-1-active">{title}</p>
        )}
        {children}
        <ConfirmButtonSet
          secondaryText={secondaryButtonText}
          secondaryOnClick={onSecondaryButtonHandler}
          confirmText={confirmButtonText}
          confirmOnClick={onConfirmButtonHandler}
          showSecondaryButton={showSecondaryButton}
          ratio={ratio}
        />
      </div>
    </Modal>
  );
};

export { ConfirmModal };
