import { FC } from 'react';

import { useRecoilValue } from 'recoil';

import { userProfileSelector } from '@state/atom/auth.atom';

import { Icon } from './Icon';
const UserProfile: FC = () => {
  const userProfile = useRecoilValue(userProfileSelector);

  return (
    <div className="flex items-center text-20">
      <Icon.UserMono className="h-8 w-8 fill-[#222]" />
      <p>{userProfile?.name ?? ''}</p>
    </div>
  );
};
export { UserProfile };
