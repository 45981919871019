import { Job, Output } from '@api/mongo/interfaces/job.interface';
import { fileBucketUrl } from '@plugin/s3Image';
import JSZip from 'jszip';

export const downloadOutput = async (jobs: Job[]) => {
  const zip = new JSZip();
  const zipName = jobs.length > 1 ? 'outputs.zip' : `${jobs[0]?.name}`;

  try {
    await Promise.all(
      jobs.map((job) => addImagesToZip(zip, job.output, `${job.name}-`, `-${job._id}`)),
    );
  } catch (err) {
    alert(`이미지 다운로드에 실패했습니다.
  ${err}`);
    return;
  }

  const blob = await zip.generateAsync({ type: 'blob' });
  const link = document.createElement('a');

  link.href = URL.createObjectURL(blob);
  link.download = zipName;
  link.click();

  URL.revokeObjectURL(link.href);
};

// img를 주어진 zip에 추가
const addImagesToZip = async (zip: JSZip, output: Output, prefix = '', suffix = '') => {
  for (const [key, image] of Object.entries(output)) {
    try {
      const response = await fetch(fileBucketUrl(image.key), {
        method: 'GET',
        mode: 'cors',
        cache: 'no-cache',
      });

      const blob = await response.blob();
      const fileName = `${prefix}${key}${suffix}.png`;
      zip.file(fileName, blob, { binary: true, date: new Date() });
    } catch (err) {
      throw new Error(`${err}`);
    }
  }
};
