import { FC, HTMLAttributes, useEffect, useState } from 'react';
import React from 'react';

import { JobStatus } from '@api/mongo/interfaces/status.interface';

type Props = HTMLAttributes<HTMLDivElement> & {
  status?: JobStatus;
  count?: number;
};

const StatsItem: FC<Props> = React.memo(
  ({ status = JobStatus.Todo, count = 0, ...props }) => {
    const [prevCount, setPrevCount] = useState(count);
    const [animate, setAnimate] = useState(false);

    useEffect(() => {
      if (count !== prevCount) {
        setAnimate(true);
        setPrevCount(count);

        // 애니메이션 지속 시간 후 애니메이션 상태 초기화
        const timer = setTimeout(() => setAnimate(false), 800);

        // Cleanup
        return () => clearTimeout(timer);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [count]);

    return (
      <div
        className={`flex cursor-pointer flex-col justify-between rounded-md  bg-gray-100 p-4 text-20 shadow-md hover:ring-2 max-sm:p-3
        ${animate ? 'twinkling-animation' : ''}
        `}
        {...props}
      >
        <div className="font-semibold max-sm:text-16">{status}</div>
        <div className="max-sm:text-16">{count.toLocaleString()}건</div>
      </div>
    );
  },
);
export { StatsItem };
