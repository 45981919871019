import { Icon } from '@components/Icon';
import { Button } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '@hooks/useAuth';

const LoginButton = () => {
  const { isAuthenticated, logout } = useAuth();
  const navigate = useNavigate();

  const logoutButtonHandler = () => {
    if (isAuthenticated) {
      logout();
      navigate('/');
    } else {
      navigate('/');
    }
  };

  return (
    <Button
      onClick={logoutButtonHandler}
      className="flex h-[43px] items-center gap-x-1 bg-white"
    >
      <Icon.LogoutMono />
      {isAuthenticated ? 'Logout' : 'Login'}
    </Button>
  );
};
export { LoginButton };
