import { FC } from 'react';

import Modal from '@components/Modal/Modal';

type ImagePreviewModalProps = { openId: string; fullUrl: string };

const ImagePreviewModal: FC<ImagePreviewModalProps> = ({ openId, fullUrl }) => {
  return (
    <Modal closeButton openId={openId}>
      <div className="rounded-lg bg-white p-8">
        <img
          loading="lazy"
          src={fullUrl}
          alt={`original-${openId}`}
          className=" max-h-[80vh] max-w-[80vw] object-cover"
        ></img>
      </div>
    </Modal>
  );
};
export { ImagePreviewModal };
