import { useMemo } from 'react';

import { useRecoilValue } from 'recoil';

import { fetcher, queryString } from '@hooks';
import { useQuery } from 'react-query';
import { useLocation } from 'react-use';

import { JOBS_URL, MIN_DATE } from './useGetJobsPolling';
import { jobsViewCountAtom } from '../state/JobContainer.atom';

const useGetJobsPageCount = () => {
  // const status = useSearchParam('status');
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const status = queryParams.get('status');
  const viewCount = useRecoilValue(jobsViewCountAtom);

  const args = useMemo(() => {
    const filter: any = {
      createdAt: { $gte: MIN_DATE },
    };
    if (status) {
      filter.status = status;
    }
    return {
      filter,
    };
  }, [status]);

  const { data: totalJobsCount } = useQuery<number>(
    `${JOBS_URL}/count?${queryString({ ...args })}`,
    fetcher,
    { refetchInterval: 2000 },
  );

  const totalPages = useMemo(() => {
    return Math.ceil((totalJobsCount ?? 0) / viewCount);
  }, [totalJobsCount, viewCount]);

  return { totalPages, totalJobsCount };
};
export { useGetJobsPageCount };
