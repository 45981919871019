import { useCallback, useRef } from 'react';

import { useSetRecoilState } from 'recoil';

import { joinedFilename } from 'src/@utils/joinedFileName';
import { Image } from '@api/mongo/interfaces';
import { nestMongoApi } from '@api/mongo/controllers';
import { Job, JobCreateDto } from '@api/mongo/interfaces/job.interface';
import { useAppLoading } from '@hooks/useAppLoading';
import { createImageArrayApi } from '@api/createImage';
import { modalOpenAtomFamily } from '@state/atom/openAtom';
import { UseFormGetValues, FieldValues } from 'react-hook-form';
import _ from 'lodash';

import { MultipleImageInputHandle } from '../components/MultipleImageInput';

const useCreateBatchJob = ({
  mode,
  getValues,
  confirmModalId,
  validModalId,
  successModalId,
}: {
  mode: number;
  getValues: UseFormGetValues<FieldValues>;
  confirmModalId: string;
  validModalId: string;
  successModalId: string;
}) => {
  const { create, findById } = nestMongoApi<Job, JobCreateDto>('/general/job');
  const { loadingTryCatch } = useAppLoading();
  const sourceRef = useRef<MultipleImageInputHandle>(null);
  const targetRef = useRef<MultipleImageInputHandle>(null);
  const opticalRef = useRef<MultipleImageInputHandle>(null);
  const cardRef = useRef<MultipleImageInputHandle>(null);

  const setConfirmModal = useSetRecoilState(modalOpenAtomFamily(confirmModalId));
  const setValidModal = useSetRecoilState(modalOpenAtomFamily(validModalId));
  const setSuccessModal = useSetRecoilState(modalOpenAtomFamily(successModalId));

  const getFilesFromRefs = () => {
    const sourceFiles = sourceRef.current?.getOriginFiles() ?? [];
    const targetFiles = targetRef.current?.getOriginFiles() ?? [];
    const opticalFiles = opticalRef.current?.getOriginFiles() ?? [];
    const cardFile = cardRef.current?.getOriginFiles() ?? [];
    return { sourceFiles, targetFiles, opticalFiles, cardFile };
  };

  const getPreviewUrlsFromRefs = () => {
    const sourceUrls = sourceRef.current?.getPreviewUrls() ?? [];
    const targetUrls = targetRef.current?.getPreviewUrls() ?? [];
    const opticalUrls = opticalRef.current?.getPreviewUrls() ?? [];
    const cardUrls = cardRef.current?.getPreviewUrls() ?? [];
    return { sourceUrls, targetUrls, opticalUrls, cardUrls };
  };

  const createBatchJob = useCallback(
    async (images: (Image | undefined)[], inputName: string, opticalName: string) => {
      // const superJobName = getValues('superJobName'); //todo superjob api 추가 예정
      const createJobDto = {
        name: joinedFilename(images),
        input: {
          source: images[0]?._id ?? '',
          target: images[1]?._id ?? '',
          optical: images[2]?._id ?? '',
          card: images[3]?._id ?? '',
          name: inputName,
          optical_name: opticalName,
        },
      };
      const { data: createJobResult } = await create(createJobDto);
      await findById(`${createJobResult._id}/run`);
    },
    [create, findById],
  );

  //모드에 따른 이미지 조건 검사 후 모달 오픈 * optical_name 추가
  const validateRunMode = () => {
    const { sourceFiles, targetFiles, opticalFiles, cardFile } = getFilesFromRefs();
    const { opticalUrls } = getPreviewUrlsFromRefs();

    const hasRequiredFiles =
      cardFile?.length > 0 && targetFiles?.length && opticalFiles?.length;

    const isValidForModeOne =
      hasRequiredFiles && targetFiles?.length === opticalFiles?.length;

    const isValidForModeTwo =
      hasRequiredFiles &&
      targetFiles?.length === opticalFiles?.length &&
      targetFiles?.length === sourceFiles?.length;

    for (let i = 0; opticalFiles?.length > i; i++) {
      const opticalName: string = getValues(`optical_name-${opticalUrls[i]}`);
      if (opticalName === '') {
        alert('opticalName을 모두 작성해주세요!');
        return;
      }
    }

    if (mode === 1) {
      if (!isValidForModeOne) {
        setValidModal(true);
      } else {
        setConfirmModal(true);
      }
    }

    if (mode === 2) {
      if (!isValidForModeTwo) {
        setValidModal(true);
      } else {
        setConfirmModal(true);
      }
    }
  };

  const excuteCreateBatchJob = useCallback(async () => {
    const { sourceFiles, targetFiles, opticalFiles, cardFile } = getFilesFromRefs();
    const { opticalUrls } = getPreviewUrlsFromRefs();

    const inputName = getValues('inputName');
    //배치잡 모두 생성될때까지 로딩
    loadingTryCatch(async () => {
      try {
        const [sourceImages, targetImages, opticalImages, cardImages] = await Promise.all(
          [
            createImageArrayApi(sourceFiles),
            createImageArrayApi(targetFiles),
            createImageArrayApi(opticalFiles),
            createImageArrayApi(cardFile),
          ],
        );

        if (mode === 1) {
          for (const sourceImage of sourceImages) {
            for (let i = 0; i < targetImages.length; i++) {
              const opticalName = getValues(`optical_name-${opticalUrls[i]}`);
              createBatchJob(
                [sourceImage, targetImages[i], opticalImages[i], cardImages[0]],
                inputName,
                opticalName,
              );
            }
          }
        } else if (mode === 2) {
          for (let i = 0; i < sourceImages.length; i++) {
            const opticalName = getValues(`optical_name-${opticalUrls[i]}`);
            createBatchJob(
              [sourceImages[i], targetImages[i], opticalImages[i], cardImages[0]],
              inputName,
              opticalName,
            );
          }
        }

        setSuccessModal(true);
      } catch (err) {
        console.error(`RunBatchJob error,${err}`);
      }
    });
  }, [createBatchJob, getValues, loadingTryCatch, mode, setSuccessModal]);

  return {
    sourceRef,
    targetRef,
    opticalRef,
    cardRef,
    getFilesFromRefs,
    getPreviewUrlsFromRefs,
    excuteCreateBatchJob,
    validateRunMode,
  };
};

export { useCreateBatchJob };
