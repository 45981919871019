import { useEffect, useMemo } from 'react';

import { io } from 'socket.io-client';

import { useAuth } from './useAuth';

export const useWebSocket = (webSocketUrl: string) => {
  const url = `${process.env.REACT_APP_WS_URL}${webSocketUrl}`;
  const { token } = useAuth();

  const socket = useMemo(() => {
    return io(url, {
      extraHeaders: { Authorization: `Bearer ${token}` },
    });
  }, [token, url]);

  //소켓 연결 종료
  useEffect(() => {
    return () => {
      if (socket && socket?.connected) {
        console.log(`WebSocket disconnect id:${socket.id} `);
        socket.disconnect();
      }
    };
  }, [socket]);

  return { socket };
};
