import axios, { InternalAxiosRequestConfig } from 'axios';
import { handleAxiosDates } from 'src/@utils/date.utils';

export const api_ = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

// api_.interceptors.request.use((config: InternalAxiosRequestConfig) => {
api_.interceptors.request.use((config: InternalAxiosRequestConfig) => {
  const token = localStorage.getItem('token-buddy');
  if (token && config.headers) config.headers.Authorization = `Bearer ${token}`;
  return config;
});

api_.interceptors.response.use((originalResponse) => {
  handleAxiosDates(originalResponse.data);
  return originalResponse;
});

export const api_session = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

api_session.interceptors.request.use((config: InternalAxiosRequestConfig) => {
  const sessionToken = sessionStorage.getItem('token-buddy');
  if (sessionToken && config.headers)
    config.headers.Authorization = `Bearer ${sessionToken}`;
  return config;
});

api_session.interceptors.response.use((originalResponse) => {
  handleAxiosDates(originalResponse.data);
  return originalResponse;
});

api_.interceptors.request.use((config: InternalAxiosRequestConfig) => {
  const token = localStorage.getItem('token-buddy');
  if (token && config.headers) config.headers.Authorization = `Bearer ${token}`;
  return config;
});

api_.interceptors.response.use((originalResponse) => {
  handleAxiosDates(originalResponse.data);
  return originalResponse;
});

api_session.interceptors.request.use((config: InternalAxiosRequestConfig) => {
  const sessionToken = sessionStorage.getItem('token-buddy');
  if (sessionToken && config.headers)
    config.headers.Authorization = `Bearer ${sessionToken}`;
  return config;
});

api_session.interceptors.response.use((originalResponse) => {
  handleAxiosDates(originalResponse.data);
  return originalResponse;
});
