import { useEffect } from 'react';

import { useRecoilState } from 'recoil';

import { modalOpenAtomFamily } from '@state/atom/openAtom';

//* 모바일 환경에서 뒤로가기 누를 시 모달만 꺼지도록
const useModalHook = (openId: string) => {
  const [open, setOpen] = useRecoilState(modalOpenAtomFamily(openId));

  useEffect(() => {
    const handleGoBack = () => {
      setOpen(false);
    };

    if (open) {
      history.pushState({ page: 'modal' }, openId);
    }

    window.addEventListener('popstate', handleGoBack);

    return () => {
      window.removeEventListener('popstate', handleGoBack);
    };
  }, [open, openId, setOpen]);
};

export { useModalHook };
