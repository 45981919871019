import { useRecoilValue } from 'recoil';

import { Body } from '@components/Body';
import { LoadingSpinnerAntd } from '@components/LoadingSpinnerAntd';
import { HeaderContainer } from '@containers/HeaderContainer';
import { JobContainer } from '@containers/JobContainer';
import { useAuth } from '@hooks/useAuth';
import { userProfileSelector } from '@state/atom/auth.atom';
import { Button } from 'antd';
import { useNavigate } from 'react-router-dom';

const JobPage = () => {
  const userProfile = useRecoilValue(userProfileSelector);
  const { token } = useAuth();
  const navigate = useNavigate();

  if (!token)
    return (
      <Body className="py-10">
        <div className="h-full w-full center-box">
          <div className="flex flex-col items-center gap-y-4">
            <p className="text-20 font-medium">로그인이 필요합니다.</p>
            <Button onClick={() => navigate('/')}>로그인하기</Button>
          </div>
        </div>
      </Body>
    );
  if (token && !userProfile)
    return (
      <Body className="py-10">
        <div className="h-full min-h-screen w-full text-20 font-bold center-box">
          <div className="flex flex-col gap-y-4">
            <LoadingSpinnerAntd size={50} />
            <p>서버 상태 확인</p>
          </div>
        </div>
      </Body>
    );

  return (
    <>
      <HeaderContainer />
      <Body className="py-10">
        <JobContainer />
      </Body>
    </>
  );
};

export { JobPage };
