import { HTMLAttributes, useRef } from 'react';

import { Icon } from '../Icon';
import Modal, { ModalRefHandle } from './Modal';
import { CustomCallback } from './interface';

interface Props extends HTMLAttributes<HTMLDivElement> {
  openId: string;
  onClick: CustomCallback<React.MouseEvent, void>;
  buttonTitle: string;
  cancelButtonTitle?: string;
  title: string;
  subtitle?: string;
}

export default function DismissModal({
  openId,
  onClick,
  buttonTitle,
  cancelButtonTitle = '취소',
  title,
  subtitle,
  children,
}: Props) {
  const modalRef = useRef<ModalRefHandle>(null);

  const onCloseModal = () => {
    modalRef.current?.onCloseModal();
  };

  return (
    <Modal openId={openId} className="mx-10 bg-white p-4" ref={modalRef}>
      <div className="sm:flex sm:items-start">
        <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
          <Icon.Exclamation className="h-6 w-6 text-red-600" aria-hidden="true" />
        </div>
        <div className="mt-3 text-center sm:ml-4 sm:mt-1.5 sm:text-left">
          <h3 className="text-xl font-medium leading-6 text-gray-900">{title}</h3>
          <div className="mt-2">
            <p className="whitespace-pre-wrap break-keep text-base font-medium text-gray-500">
              {subtitle}
            </p>
          </div>
        </div>
      </div>
      {children}
      <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
        <button
          type="button"
          className="inline-flex w-full justify-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none sm:ml-3 sm:w-auto sm:text-sm"
          onClick={(e) => {
            modalRef.current?.onCloseAsyncModal(() => onClick(e));
          }}
        >
          {buttonTitle}
        </button>
        <button
          type="button"
          className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:text-gray-500 sm:mt-0 sm:w-auto sm:text-sm"
          onClick={onCloseModal}
        >
          {cancelButtonTitle}
        </button>
      </div>
    </Modal>
  );
}
