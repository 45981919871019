import React, { FC, useEffect, useState } from 'react';

import { useRecoilValue, useSetRecoilState } from 'recoil';

import { TextField } from '@components/TextField';
import { useForm } from 'react-hook-form';
import _ from 'lodash';
import { useQueryClient } from 'react-query';
import { UserProfile } from '@components/UserProfile';
import { modalOpenAtomFamily } from '@state/atom/openAtom';
import Modal from '@components/Modal/Modal';
import { Icon } from '@components/Icon';
import { JobCreateInput } from '@api/mongo/interfaces/job.interface';
import { userProfileSelector } from '@state/atom/auth.atom';

import { RunButton } from './RunButton';
import { jobsUrl } from '../state/jobContainer.hook';
import { useCreateRunJob } from '../hooks/useCreateRunJob';
import { CreateInputCardList } from './CreateInputCardList';
import { jobCreateInputAtom } from '../state/JobContainer.atom';

//객체 순서 지켜야함
export const defaultFormValue: JobCreateInput = {
  source: null,
  target: null,
  optical: null,
  card: null,
  inputName: '',
  optical_name: '',
  jobName: '',
};

const CreateJobRow: FC = React.memo(() => {
  const validModalId = `create-job-valid-modal`;
  const setModal = useSetRecoilState(modalOpenAtomFamily(validModalId));
  const userProfile = useRecoilValue(userProfileSelector);

  const jobCreateInput = useRecoilValue(jobCreateInputAtom);

  const [shouldReRunAnimate, setShouldReRunAnimate] = useState(false);
  const [shouldRunAnimate, setShouldRunAnimate] = useState(false);

  const queryClient = useQueryClient();
  const { register, setValue, getValues } = useForm<JobCreateInput>({
    defaultValues: {
      ...defaultFormValue,
    },
  });

  const { createRunJob } = useCreateRunJob();

  const invalidateJobs = async () => {
    await queryClient.invalidateQueries(jobsUrl(userProfile?._id ?? ''));
  };

  const runAnimate = () => {
    //클릭마다 애니메이션 실행
    setShouldRunAnimate(false);
    requestAnimationFrame(() => {
      setShouldRunAnimate(true);
    });
  };

  const runButtonHandler = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    try {
      const inputName = getValues('inputName');
      const jobName = getValues('jobName');
      const opticalName = getValues('optical_name');

      const card = getValues('card');
      const optical = getValues('optical');
      const target = getValues('target');
      const source = getValues('source');

      console.log(`card :`, card);
      console.log(`optical :`, optical);
      console.log(`target :`, target);
      console.log(`source :`, source);
      const imageFiles = Object.values(
        _.omit(jobCreateInput, ['inputName', 'jobName', 'optical_name']),
      );

      // 빈 인풋 있을 시 경고 모달
      if (!inputName || !jobName || imageFiles.some((file) => !file)) {
        setModal(true);
        return;
      }

      runAnimate();
      await createRunJob(imageFiles, jobName, inputName, opticalName);
      await invalidateJobs();
    } catch (error) {
      console.log(`create job error :`, error);
    }
  };

  // reRunJob 등록
  useEffect(() => {
    setValue('inputName', jobCreateInput.inputName as unknown as string);
    setValue('jobName', jobCreateInput.jobName ?? '');
    setValue('optical_name', jobCreateInput.optical_name ?? '');
  }, [jobCreateInput, setValue]);

  return (
    <form>
      <div
        className={`mx-auto w-full max-w-[95vw] space-y-4 rounded-lg bg-white p-4 drop-shadow-lg
        ${shouldReRunAnimate ? 'rerun-animation' : ''}
        ${shouldRunAnimate ? 'run-animation' : ''}
        `}
        onAnimationEnd={() => {
          setShouldReRunAnimate(false);
          setShouldRunAnimate(false);
        }}
      >
        <div className="relative flex w-full justify-between pb-6">
          {/* Experiment Name Field */}
          <div>
            <TextField
              tabIndex={1}
              placeholder="Experiment Name"
              className="bg-gray-100 px-4"
              {...register('jobName', {})}
            />
          </div>
          {/* job status  */}
          <div className="mr-1 flex items-center gap-x-4">
            <UserProfile />
            <RunButton onClick={runButtonHandler} />
          </div>
        </div>
        {/* input & output IMG */}
        <div className="grid grid-cols-9 gap-x-8">
          <CreateInputCardList register={register} />
        </div>
      </div>

      <Modal openId={validModalId} closeButton className="bg-white px-6 py-10">
        <div className="space-y-4 text-18">
          <div className="flex items-center gap-x-2">
            <Icon.CheckCircle className="h-10 w-10 fill-red-500" aria-hidden="true" />
            <p className="text-24 font-semibold">비어있는 input이 있습니다.</p>
          </div>
        </div>
      </Modal>
    </form>
  );
});
export { CreateJobRow };
