import { useRecoilState } from 'recoil';

import { Select } from 'antd';

import { jobsViewCountAtom } from '../state/JobContainer.atom';

//* jobs page에서 사용자가 보려는 jobs 갯수 설정 컴포넌트
const JobsViewCountSelector = () => {
  const [jobsViewCount, setJobsViewCount] = useRecoilState(jobsViewCountAtom);

  return (
    <div className="relative">
      {/* //! findDOMNode is deprecated in StrictMode. 에러때문에 div 한층 감싸놓음 */}
      <p className="absolute -top-5 text-gray-500">ViewCount</p>
      <Select
        className="min-w-[60px] text-center"
        defaultValue={jobsViewCount}
        onChange={(value) => {
          setJobsViewCount(value);
        }}
        size="large"
        options={[
          { value: 5, label: '5' },
          { value: 10, label: '10' },
          { value: 20, label: '20' },
          { value: 50, label: '50' },
          { value: 100, label: '100' },
        ]}
      />
    </div>
  );
};
export { JobsViewCountSelector };
