import { User } from '@api/mongo/interfaces';
import { useQuery } from 'react-query';
import { api_ } from '@plugin/axios';

import { useAuth } from './useAuth';

const useUser = () => {
  const { token } = useAuth();

  const queryFn = async () => {
    const { data } = await api_.get('auth/me');
    return data;
  };

  const { data: user } = useQuery<User>(['auth/me', token], queryFn, {
    enabled: !!token,
  });
  return { user };
};
export { useUser };
