import { DefaultValue, atom, selector } from 'recoil';

import { User } from '@api/mongo/interfaces';
import _ from 'lodash';

export const tokenAtom = atom<string | null>({
  key: 'tokenAtom',
  default: localStorage.getItem('token-buddy'),
});

export const tokenSelector = selector<string | null>({
  key: 'tokenSelector',
  get: ({ get }) => {
    const token = get(tokenAtom);
    return token;
  },
  set: ({ set }, val: string | null | DefaultValue) => {
    if (val instanceof DefaultValue) {
      localStorage.removeItem('token-buddy');
    } else if (val) {
      localStorage.setItem('token-buddy', val);
      sessionStorage.removeItem('token-buddy');
    } else {
      localStorage.removeItem('token-buddy');
    }
    set(tokenAtom, val);
  },
});

//* 로그인시 사용되는 user
export const userProfileAtom = atom<User | null>({
  key: 'userProfileAtom',
  default: _.isNil(localStorage.getItem('userProfile-buddy'))
    ? null
    : (JSON.parse(localStorage.getItem('userProfile-buddy') as string) as User),
});

export const userProfileSelector = selector<User | null>({
  key: 'userProfileSelector',
  get: ({ get }) => {
    const userProfile = get(userProfileAtom);
    return userProfile;
  },
  set: ({ set }, val: User | null | DefaultValue) => {
    if (val instanceof DefaultValue) {
      localStorage.removeItem('userProfile-buddy');
    } else if (val) {
      localStorage.setItem('userProfile-buddy', JSON.stringify(val));
      sessionStorage.removeItem('userProfile-buddy');
      set(userProfileAtom, val);
    } else {
      localStorage.removeItem('userProfile-buddy');
    }
  },
});

//* imp
export const impUidAtom = atom<string | null>({
  key: 'impUidAtom',
  default: localStorage.getItem('impUid'),
});

export const impUidSelector = selector<string | null>({
  key: 'impUidSelector',
  get: ({ get }) => {
    const impUid = get(impUidAtom);
    return impUid;
  },
  set: ({ set }, val: string | null | DefaultValue) => {
    if (val instanceof DefaultValue) {
      localStorage.removeItem('impUid');
    } else if (val) {
      localStorage.setItem('impUid', val);
    } else {
      localStorage.removeItem('impUid');
    }
    set(impUidAtom, val);
  },
});
